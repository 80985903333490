import { Flag } from '@material-ui/icons';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  Tag,
} from '@superdispatch/ui';
import { OrderTag } from 'core/dictionary/OrderTag';
import { lowerCase, startCase } from 'lodash-es';
import { useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useSuperPayPaymentDueOn } from 'shared/helpers/superpay/SuperPayHelpers';
import Order from 'shared/types/order';
import { joinStrings } from 'shared/utils/StringUtils';
import { usePriceNegotiation } from '../../data/PriceNegotiationAPI';
import { PriceNegotiationStatus } from '../actions/price-negotiation/PriceNegotiationStatus';
import { useMightBeLateDays } from './DelayedOrderHelpers';
import { OrderAlertStatuses, useOrderDelayStatus } from './OrderAlertStatuses';
import { OrderDeliveryVerificationStatus } from './OrderDeliveryVerificationStatus';
import { OrderPaymentStatus } from './OrderPaymentStatus';
import {
  OfferDeclineTooltip,
  OrderStatusTag,
  PostedTag,
} from './OrderStatusTags';

interface OrderStatusProps {
  order: Order;
  showDelays?: boolean;
  inline?: boolean;
  hasUnassignedVehicles?: boolean;
}

export function OrderStatus({
  order,
  showDelays,
  inline,
  hasUnassignedVehicles,
}: OrderStatusProps) {
  const { data: priceNegotiation } = usePriceNegotiation(order);
  const { agingDelay, pickupDelay, deliveryDelay } = useOrderDelayStatus(order);
  const { isShowPaymentDueOn, isDateLessCurrent, paymentExpectedDate } =
    useSuperPayPaymentDueOn(order);

  const shouldShowPostedTag =
    order.is_posted_to_centraldispatch || order.is_posted_to_loadboard;

  const renderOrderDeclinedInfoTooltip =
    !!order.offer_decline_reasons || !!order.offer_decline_comment ? (
      <OfferDeclineTooltip
        reason={order.offer_decline_reasons}
        comment={order.offer_decline_comment}
        carrierName={order.offer_carrier_name}
      />
    ) : null;

  const renderOrderCanceledInfoTooltip =
    !!order.offer_cancel_reasons?.length || !!order.offer_cancel_comment ? (
      <OfferDeclineTooltip
        carrierName={order.offer_carrier_name}
        comment={order.offer_cancel_comment}
        reason={joinStrings(
          ',',
          ...(order.offer_cancel_reasons?.map((r) => startCase(lowerCase(r))) ||
            []),
        )}
      />
    ) : null;

  const isCounterOfferStatus =
    order.status === 'pending' || order.status === 'declined';

  const isManuallyChanged =
    order.status === 'invoiced'
      ? !!order.invoice?.adjusted_invoice_date
      : order.is_status_changed_manually;

  const superPay = order.payment?.super_pay;

  const showPaymentStatus =
    superPay &&
    ((order.status !== 'paid' && order.status !== 'order_canceled') ||
      order.is_archived);

  const { user } = useUserState();
  const mightBeLateDays = useMightBeLateDays(order);

  const isBroker = user?.shipper.shipper_type === 'BROKER';

  const canHaveDelay = useMemo(
    () =>
      (order.status === 'new' && !order.is_archived && !order.is_on_hold) ||
      order.is_on_hold ||
      order.is_posted_to_centraldispatch ||
      order.is_posted_to_loadboard ||
      order.status === 'declined' ||
      order.status === 'pending' ||
      order.status === 'order_canceled' ||
      order.status === 'canceled',
    [order],
  );

  const alertStatuses = useMemo(() => {
    const statuses = [];

    if (hasUnassignedVehicles) {
      statuses.push('Has unassigned vehicles');
    }

    if (!showDelays || !isBroker) {
      return statuses;
    }

    if (canHaveDelay) {
      if (mightBeLateDays > 0) {
        if (order.eta_information?.pickup_eta) {
          statuses.push(`Running late for pickup by ${mightBeLateDays}d`);
        } else {
          statuses.push(`Running late for delivery by ${mightBeLateDays}d`);
        }
      }

      if (agingDelay) {
        statuses.push(`Aging for ${agingDelay}`);
      }
      if (pickupDelay) {
        statuses.push(`${pickupDelay} pickup delay`);
      }
      if (deliveryDelay) {
        statuses.push(`${deliveryDelay} delivery delay`);
      }
    }

    if (isShowPaymentDueOn && isDateLessCurrent) {
      statuses.push(`Carrier Payment Due on ${paymentExpectedDate}`);
    }

    return statuses;
  }, [
    hasUnassignedVehicles,
    showDelays,
    isShowPaymentDueOn,
    isDateLessCurrent,
    isBroker,
    canHaveDelay,
    mightBeLateDays,
    agingDelay,
    pickupDelay,
    deliveryDelay,
    paymentExpectedDate,
    order.eta_information?.pickup_eta,
  ]);

  if (inline) {
    return (
      <Inline>
        {shouldShowPostedTag && <PostedTag order={order} />}
        {priceNegotiation?.active && (
          <PriceNegotiationStatus
            order={order}
            priceNegotiation={priceNegotiation}
          />
        )}
        {(isCounterOfferStatus || !shouldShowPostedTag) && (
          <OrderStatusTag order={order} />
        )}
        {isManuallyChanged && (
          <Tag color="grey" variant="subtle">
            Manual
          </Tag>
        )}

        <OrderDeliveryVerificationStatus order={order} />

        {order.is_instantly_dispatched && (
          <Tag color="grey" variant="subtle">
            Instant Dispatch
          </Tag>
        )}
        {showPaymentStatus && <OrderPaymentStatus order={order} />}
        {renderOrderDeclinedInfoTooltip}
        {renderOrderCanceledInfoTooltip}
        {order.is_flagged && (
          <Flag htmlColor={ColorDynamic.Red500} aria-label="order flagged" />
        )}
        {order.tags.map((id) => (
          <OrderTag key={id} tagId={id} />
        ))}
        {alertStatuses.length > 0 && (
          <OrderAlertStatuses statuses={alertStatuses} inline={inline} />
        )}
      </Inline>
    );
  }

  return (
    <Stack space="xxsmall">
      <Columns space="xsmall" align="center">
        <Column>
          <Stack space="xxsmall">
            {shouldShowPostedTag && <PostedTag order={order} />}

            {priceNegotiation?.active && (
              <PriceNegotiationStatus
                order={order}
                priceNegotiation={priceNegotiation}
              />
            )}

            {(isCounterOfferStatus || !shouldShowPostedTag) && (
              <Inline space="xxsmall" verticalAlign="center" noWrap={true}>
                <OrderStatusTag order={order} />

                {renderOrderDeclinedInfoTooltip}

                {renderOrderCanceledInfoTooltip}
              </Inline>
            )}

            {isManuallyChanged && (
              <Tag color="grey" variant="subtle">
                Manual
              </Tag>
            )}

            <OrderDeliveryVerificationStatus order={order} />

            {order.is_instantly_dispatched && (
              <Tag color="grey" variant="subtle">
                Instant Dispatch
              </Tag>
            )}

            {showPaymentStatus && <OrderPaymentStatus order={order} />}
          </Stack>
        </Column>

        <Column width="content">
          {order.is_flagged && (
            <Flag htmlColor={ColorDynamic.Red500} aria-label="order flagged" />
          )}
        </Column>
      </Columns>

      {order.tags.map((tagId) => (
        <OrderTag key={tagId} tagId={tagId} />
      ))}

      {alertStatuses.length > 0 && (
        <OrderAlertStatuses statuses={alertStatuses} inline={inline} />
      )}
    </Stack>
  );
}
