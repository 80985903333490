import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  FormikContextTypeEnhanced,
  FormikMaxLengthTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import { DrawerActions, DrawerTitle, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import {
  FormikCheckboxArrayField,
  FormikCheckboxArrayGroup,
} from 'shared/form/FormikCheckboxArray';
import { FormikDrawer } from 'shared/form/FormikDrawer';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { OrderActionSource } from '../OrderActionsAnalytics';
import { SubmitEscalationDTO } from './data/EscalationDTO';

const EscalationReasonsMap = [
  { name: 'charges_due', label: 'Charges Due' },
  { name: 'vehicle_not_at_location', label: 'Vehicle is Not at Location' },
  { name: 'incorrect_phone', label: 'Incorrect Phone Number' },
  { name: 'location_wont_release', label: 'Location Will Not Release' },
  { name: 'location_changed', label: 'Location Changed' },
  { name: 'no_returned_contact', label: 'No Returned Calls/Emails' },
  { name: 'other', label: 'Other' },
] as const;

const StyledBox = styled.div`
  display: flex;
  padding: 16px 32px;
  align-items: center;
  align-self: stretch;
`;

const StyledActionsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  align-self: stretch;
`;

interface SubmitEscalationDrawerContentProps {
  formik: FormikContextTypeEnhanced<
    SubmitEscalationDTO & { checkboxes: string[] },
    unknown
  >;
  onClose: () => void;
}

function SubmitEscalationDrawerContent({
  formik,
  onClose,
}: SubmitEscalationDrawerContentProps) {
  return (
    <>
      <DrawerTitle
        title="Submit Escalation"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />

      <StyledBox>
        <FormikCheckboxArrayGroup name="checkboxes">
          <Stack space="xsmall">
            <TextBox variant="heading-6" color="secondary">
              What is the reason?
            </TextBox>
            {EscalationReasonsMap.map((reason) => (
              <Stack key={reason.name} space="xsmall">
                <FormikCheckboxArrayField
                  name={reason.name}
                  label={<Typography>{reason.label}</Typography>}
                  disabled={formik.isSubmitting}
                />
                {formik.values.checkboxes.includes(reason.name) && (
                  <Box paddingLeft="large">
                    <FormikMaxLengthTextField
                      name={`reasons.${reason.name}_details`}
                      label="Details"
                      maxLength={500}
                      fullWidth={true}
                      multiline={true}
                      rows={4}
                      disabled={formik.isSubmitting}
                    />
                  </Box>
                )}
              </Stack>
            ))}
          </Stack>
        </FormikCheckboxArrayGroup>
      </StyledBox>

      <DrawerActions>
        <StyledActionsBox>
          <Typography color="textSecondary">
            The escalation will be submitted to Alpha Shippers, and the shipper
            will be notified.
          </Typography>

          <Button type="submit" pending={formik.isSubmitting}>
            Save & Submit
          </Button>
        </StyledActionsBox>
      </DrawerActions>
    </>
  );
}

interface SubmiteEscalationDrawerProps {
  order?: Order;
  source: OrderActionSource;
  onClose: () => void;
  onSubmitSuccess?: () => void;
}

export function SubmiteEscalationDrawer({
  order,
  source,
  onClose,
  onSubmitSuccess,
}: SubmiteEscalationDrawerProps) {
  const open = !!order;

  const formik = useFormikEnhanced<
    SubmitEscalationDTO & { checkboxes: string[] },
    unknown
  >({
    initialValues: {
      checkboxes: [],
      reasons: {},
      order_id: order?.id || 0,
    },
    onSubmit: () => {
      // Handle submission logic here
    },
    onSubmitSuccess: (_, values) => {
      onSubmitSuccess?.();
      trackEventLegacy('Test', {
        utm_source: source,
        values,
      });
    },
  });

  return (
    <FormikDrawer open={open} onClose={onClose}>
      <FormikProvider value={formik}>
        {open && (
          <SubmitEscalationDrawerContent onClose={onClose} formik={formik} />
        )}
      </FormikProvider>
    </FormikDrawer>
  );
}
