import { useEffect } from 'react';
import { useAppToken } from 'shared/data/AppUserState';
import './zendesk.css';
import { removeZendeskTokenCache, useZendeskToken } from './ZendeskAPI';
import { Scope, ZendeskError, ZendeskMethod } from './ZendeskDTO';

const API_KEY = '5e126156-0153-445a-bc39-9b442fa5c8ab'; //superdispatchhelp.zendesk.com
const SCRIPT_ID = 'ze-snippet';
const ZENDESK_URL = 'https://static.zdassets.com/ekr/snippet.js';

function executeZendeskCommand(
  scope: Scope,
  method: ZendeskMethod,
  ...args: unknown[]
) {
  if (window.zE) {
    window.zE(scope, method, ...args);
  }
}

export const zendeskCommands = {
  hideZendeskButton: () => {
    executeZendeskCommand('messenger', 'hide');
  },
  showZendeskButton: () => {
    executeZendeskCommand('messenger', 'show');
  },
  showZendeskDialog: () => {
    executeZendeskCommand('messenger', 'open');
  },
  logoutZendeskUser: () => {
    executeZendeskCommand('messenger', 'logoutUser');
    removeZendeskTokenCache();
  },
};

let lastToken = '';
function loginZendeskUser(token?: string) {
  executeZendeskCommand('messenger:set', 'conversationTags', ['STMS']);

  if (token && lastToken !== token) {
    lastToken = token;
    executeZendeskCommand(
      'messenger',
      'loginUser',
      (callback: (token: string) => void) => {
        callback(token);
      },
      (error?: ZendeskError) => {
        if (error?.reason.includes('email conflict')) {
          executeZendeskCommand('messenger', 'logoutUser');
          executeZendeskCommand('messenger:set', 'conversationTags', ['STMS']);
        }
      },
    );
  }
}

export function useZendesk(zendeskEnabled: boolean) {
  const appToken = useAppToken();
  const { data } = useZendeskToken(!!(zendeskEnabled && appToken));
  const token = data?.token;

  useEffect(() => {
    const zendeskScript = document.getElementById(SCRIPT_ID);

    if (zendeskEnabled && !zendeskScript) {
      const script = document.createElement('script');
      script.src = `${ZENDESK_URL}?key=${API_KEY}`;
      script.id = SCRIPT_ID;
      script.type = 'text/javascript';
      script.async = true;
      script.onload = () => {
        script.setAttribute('readyState', 'complete');
        loginZendeskUser();
      };
      document.body.appendChild(script);
    }

    if (!zendeskEnabled && zendeskScript) {
      zendeskCommands.hideZendeskButton();
    }
  }, [zendeskEnabled]);

  useEffect(() => {
    const zendeskScript = document.getElementById(SCRIPT_ID);

    const isZendeskScriptComplete =
      zendeskScript?.getAttribute('readyState') === 'complete';

    if (token && isZendeskScriptComplete) {
      loginZendeskUser(token);
    }
  }, [token]);

  return zendeskCommands;
}
