import { useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { plainToClass } from 'class-transformer';
import { CarrierRatingDeleteDialog } from 'core/ratings/CarrierRatingDeleteDialog';
import { CarrierRatingDialog } from 'core/ratings/CarrierRatingDialog';
import { CarrierRatings } from 'core/ratings/CarrierRatings';
import { CarrierInternalRecordsEditDrawer } from 'manage-carriers/core/CarrierInternalRecordsEditDrawer';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useProductTiers } from 'shared/data/TiersUtils';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useCustomerSupport } from 'shared/helpers/customer-support/CustomerSupport';
import { useQuery } from 'shared/helpers/RouteHelpers';
import { PageLayout } from 'shared/layout/PageLayout';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import styled from 'styled-components';
import { DictionaryCarrierDTO } from '../../core/dictionary/data/DictionaryDTO';
import { ReportCarrierDrawer } from '../core/ReportCarrierDrawer';
import { useCarrier } from '../data/ManageCarriersAPI';
import { CarrierAssessment } from './CarrierAssessment';
import { CarrierBillingInfo } from './CarrierBillingInfo';
import { CarrierCompanyInfo } from './CarrierCompanyInfo';
import { CarrierContactInfo } from './CarrierContactInfo';
import { CarrierDispatched } from './CarrierDispatched';
import { CarrierInternalNotes } from './CarrierInternalNotes';
import { CarrierInternalRecords } from './CarrierInternalRecords';
import { CarrierInternalRecordsBasicTier } from './CarrierInternalRecordsBasicTier';
import { CarrierProfileHeader } from './CarrierProfileHeader';
import { CarrierProfileLayout } from './CarrierProfileLayout';
import { CarrierReporting } from './CarrierReporting';
import { CarrierTermsAndConditions } from './CarrierTermsAndConditions';

const ProfileContainer = styled.div`
  max-width: calc(
    832px + 16px + 408px
  ); /** MAIN_CONTENT + SPACING(2) + INTERNAL_NOTES_CONTENT  */
  width: 100%;
  margin: auto;
`;

export function CarrierProfilePage() {
  const { showCustomerDialog } = useCustomerSupport();
  const navigate = useNavigate();
  const { usdot, guid } = useParams<{ usdot: string; guid: string }>();

  const { addSnackbar } = useSnackbarStack();
  const [dialog, setDialog] = useState<
    'rating' | 'internal-records' | 'delete-rating' | 'report-carrier'
  >();
  const [
    { tab = 'overview', open_rating_dialog, open_intercom_revoking_help },
    setQuery,
  ] = useQuery();

  const { error, data: carrier } = useCarrier(
    { guid, usdot },
    {
      onSettled: (carrierResponse) => {
        trackEvent(
          'Shipper Opened Carrier Profile',
          carrierResponse
            ? {
                carrier_name: carrierResponse.name,
                carrier_guid: carrierResponse.guid,
                is_carrier_verified: carrierResponse.is_verified,
                is_carrier_approved: carrierResponse.broker_records.approved,
                is_carrier_can_instantly_book:
                  carrierResponse.broker_records.preferred,
              }
            : undefined,
        );
      },
    },
  );

  const { isBasicTier } = useProductTiers();

  useEffect(() => {
    if (open_rating_dialog) {
      setDialog('rating');
      setQuery({ open_rating_dialog: undefined });
    }
  }, [open_rating_dialog, setQuery]);

  useEffect(() => {
    if (usdot && carrier) {
      navigate(`/manage-carriers/${carrier.guid}`, { replace: true });
    }
  }, [navigate, usdot, carrier]);

  useEffect(() => {
    if (error) {
      addSnackbar(error.message, { variant: 'error' });
    }
  }, [error, addSnackbar]);

  useEffect(() => {
    if (open_intercom_revoking_help) {
      showCustomerDialog(
        'A carrier I had dispatched a load to was revoked. What should I do?',
      );

      setQuery({ open_intercom_revoking_help: undefined });
    }
  }, [open_intercom_revoking_help, setQuery, showCustomerDialog]);

  if (!carrier) {
    return <LoadingContainer />;
  }

  return (
    <PageLayout>
      <Box
        paddingTop={['none', 'medium']}
        paddingBottom={['xxlarge', 'medium']}
        paddingLeft={['none', 'large']}
        paddingRight={['none', 'large']}
        marginBottom={['xlarge', 'none']}
      >
        <ProfileContainer>
          <CarrierProfileLayout
            activeTab={tab}
            isCarrierInternal={carrier.carrier_type === 'INTERNAL'}
            internalNotes={<CarrierInternalNotes carrier={carrier} />}
            assessment={<CarrierAssessment carrier={carrier} />}
            billingInfo={<CarrierBillingInfo carrier={carrier} />}
            companyInfo={<CarrierCompanyInfo carrier={carrier} />}
            contactInfo={<CarrierContactInfo carrier={carrier} />}
            termsAndConditions={<CarrierTermsAndConditions carrier={carrier} />}
            dispatched={
              <CarrierDispatched
                carrier={plainToClass(DictionaryCarrierDTO, carrier)}
                totalDispatched={carrier.broker_records.accepted_loads_count}
              />
            }
            actions={<CarrierReporting carrier={carrier} />}
            internalRecords={
              isBasicTier ? (
                <CarrierInternalRecordsBasicTier carrier={carrier} />
              ) : (
                <CarrierInternalRecords
                  carrier={carrier}
                  onEdit={() => {
                    setDialog('internal-records');
                  }}
                />
              )
            }
            ratings={
              <CarrierRatings
                carrier={carrier}
                onRate={() => {
                  setDialog('rating');
                }}
              />
            }
            header={
              <CarrierProfileHeader
                carrier={carrier}
                onEditRecords={() => {
                  setDialog('internal-records');
                }}
                onRate={() => {
                  setDialog('rating');
                }}
                onRatingDelete={() => setDialog('delete-rating')}
                onTabChange={(nextTab) => {
                  setQuery({
                    tab: nextTab,
                    utm_medium: 'Carrier Profile',
                    utm_content: 'Overall Rating',
                  });
                }}
              />
            }
            onTabChange={(nextTab) => {
              if (nextTab === 'ratings') {
                setQuery({
                  tab: nextTab,
                  utm_medium: 'Carrier Profile',
                  utm_content: 'Ratings Tab',
                });
              } else {
                setQuery({ tab: nextTab });
              }
            }}
          />
        </ProfileContainer>

        <CarrierRatingDialog
          open={dialog === 'rating'}
          carrierGuid={carrier.guid}
          onClose={() => {
            setDialog(undefined);
          }}
          onSubmitSuccess={() => {
            setDialog(undefined);
          }}
        />

        <CarrierRatingDeleteDialog
          carrierGuid={carrier.guid}
          open={dialog === 'delete-rating'}
          onClose={() => setDialog(undefined)}
        />

        <CarrierInternalRecordsEditDrawer
          title="Internal Records"
          open={dialog === 'internal-records'}
          carrier={carrier}
          onClose={() => {
            setDialog(undefined);
          }}
          onSubmitSuccess={(reportCarrier) => {
            setDialog(reportCarrier ? 'report-carrier' : undefined);
            addSnackbar('Successfully updated internal records', {
              variant: 'success',
            });
          }}
          onSubmitFailure={() => {
            addSnackbar('Failed to updated internal records', {
              variant: 'error',
            });
          }}
        />

        <ReportCarrierDrawer
          carrier={carrier}
          isOpen={dialog === 'report-carrier'}
          onClose={() => {
            setDialog(undefined);
          }}
        />
      </Box>
    </PageLayout>
  );
}
