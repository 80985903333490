import { renderChildren } from '@superdispatch/ui';
import { ReactNode } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
  UIMatch,
  useMatches,
} from 'react-router-dom';
import { useProductTiers } from 'shared/data/TiersUtils';
import { AppBlankLayout, AppSidebarLayout } from './AppLayouts';

export interface AuthenticatedRouteObjectHandle {
  layout?: 'sidebar' | 'blank';
  isProtected?: true;
}

export type AuthenticatedRouteObject = Omit<
  RouteObject,
  'handle' | 'children'
> & {
  children?: AuthenticatedRouteObject[];
  handle?: AuthenticatedRouteObjectHandle;
};

export function createAuthenticatedRouter(routes: AuthenticatedRouteObject[]) {
  return createBrowserRouter([
    {
      path: '/',
      Component: () => {
        const matches = useMatches() as Array<
          UIMatch<unknown, AuthenticatedRouteObjectHandle | undefined>
        >;
        const isProtected = matches.some(
          (match) => !!match.handle?.isProtected,
        );

        const layout =
          matches.find((match) => !!match.handle?.layout)?.handle?.layout ??
          'sidebar';

        const LayoutComponent =
          layout === 'blank' ? AppBlankLayout : AppSidebarLayout;

        if (isProtected) {
          return (
            <ProtectedRoute>
              <LayoutComponent>
                <Outlet />
              </LayoutComponent>
            </ProtectedRoute>
          );
        }

        return (
          <LayoutComponent>
            <Outlet />
          </LayoutComponent>
        );
      },
      children: routes as RouteObject[],
    },
  ]);
}

export function ProtectedRoute({ children }: { children: ReactNode }) {
  const { isBasicTier } = useProductTiers();

  if (isBasicTier) {
    return <Navigate to="/orders" />;
  }

  return renderChildren(children);
}
