import { Link, Tooltip, Typography } from '@material-ui/core';
import { Box as GridBox, Flex } from '@rebass/grid';
import { formatPaymentMethod, formatPaymentTerm } from '@superdispatch/sdk';
import {
  ColorDynamic,
  Inline,
  Stack,
  useSnackbarStack,
  useUID,
} from '@superdispatch/ui';
import { Box, DescriptionLineItem } from '@superdispatch/ui-lab';
import { isSuperpayPayment } from 'core/PaymentUtils';
import { Price } from 'core/Price';
import { round, startCase } from 'lodash-es';
import { DateTime } from 'luxon';
import { PricingRecommendation } from 'orders/core/pricing-insights/PricingRecommendation';
import { isOrderBelowRecommendedPrice } from 'orders/core/pricing-insights/PricingRecommendationHelpers';
import { useOrderAPI, useOrderCache } from 'orders/data/OrderAPI';
import { OrderViewDistanceWithPrice } from 'orders/view/OrderViewDistanceWithPrice';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { CarrierRequestedSuperPay } from 'shared/helpers/superpay/SuperPayHelpers';
import { SuperPayLogo } from 'shared/icons/SuperPayLogo';
import Order, { OrderPayment } from 'shared/types/order';
import {
  DefinitionList,
  DefinitionListColumnLegacy,
  DefinitionListRowLegacy,
} from 'shared/ui/DefinitionList';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { Divider } from 'shared/ui/Divider';
import { useProductTiers } from '../../shared/data/TiersUtils';
import { PriceNegotiationDrawer } from '../core/actions/price-negotiation/PriceNegotiationDrawer';
import { calculatePriceNegotiationInfo } from '../data/dto/PriceNegotiationDTO';
import { usePriceNegotiation } from '../data/PriceNegotiationAPI';
import { Collapse, CollapseButton, useCollapse } from './Collapse';
import { NoData } from './NoData';
import { OrderViewCustomerInvoice } from './OrderViewCustomerInvoice';
import { OrderViewNote } from './OrderViewNote';

const PAYMENT_CARD_STATE_KEY = 'payment_card_state';

function OrderViewPaymentRecommendation({ order }: { order: Order }) {
  const { addSnackbar } = useSnackbarStack();
  const { updateOrderPrice } = useOrderAPI();
  const { invalidateOrder } = useOrderCache();

  if (!isOrderBelowRecommendedPrice(order)) {
    return null;
  }

  return (
    <PricingRecommendation
      order={order}
      source="Order View"
      onPriceClick={(price) => {
        updateOrderPrice(order.id, price)
          .then(() => {
            void invalidateOrder(order.guid);
            addSnackbar(
              `Total Carrier Price updated to ${formatCurrency(price, {
                minimumFractionDigits: 0,
              })}`,
            );
          })
          .catch((error) => {
            addSnackbar(error.message, { variant: 'error' });
          });
      }}
    />
  );
}

interface PaymentProps {
  order: Order;
}

export const OrderViewPayment: React.ComponentType<PaymentProps> = ({
  order,
  order: {
    price: totalCarrierPrice,
    broker_fee: dueFromCarrier,
    customer_payment: { tariff: totalTariff, deposit } = {},
    invoice,
    customer_payment = {},
  },
}) => {
  const uid = useUID();
  const { user } = useUserState();
  const [isOpenPriceNegotiationDrawer, setIsOpenPriceNegotiationDrawer] =
    useState(false);
  const today = DateTime.local().startOf('day');
  const shipperType = user?.shipper.shipper_type;
  const paymentCardState = useStorageValue(PAYMENT_CARD_STATE_KEY);
  const { expanded, toggleExpand } = useCollapse(
    paymentCardState === 'expanded',
  );

  const { data: priceNegotiation } = usePriceNegotiation(order);
  const priceNegotiationInfo = useMemo(
    () =>
      priceNegotiation?.active &&
      calculatePriceNegotiationInfo(order.price, priceNegotiation),
    [order.price, priceNegotiation],
  );

  const payment = order.payment || ({} as OrderPayment);
  const grossProfit =
    totalTariff && totalCarrierPrice && totalTariff - totalCarrierPrice;
  const grossProfitPercentage =
    grossProfit && totalTariff && (grossProfit / totalTariff) * 100;
  const hideCarrierInvoiced = !invoice || !invoice.date || !invoice.url;
  const hideCarrierSection =
    shipperType !== 'BROKER' &&
    hideCarrierInvoiced &&
    !payment.sent_date &&
    !payment.terms &&
    !payment.method &&
    !payment.amount &&
    !payment.reference_number &&
    !payment.notes;
  const hideCustomerInvoiced =
    !order.customer_invoice?.sent_date &&
    (!order.customer_invoices || order.customer_invoices.length === 0);
  const hideMainSection =
    !totalTariff &&
    (!deposit || shipperType === 'CUSTOMER') &&
    (hideCustomerInvoiced || shipperType === 'BROKER') &&
    !totalCarrierPrice &&
    !dueFromCarrier &&
    (!totalCarrierPrice || !totalTariff);
  const hideCustomerSection =
    shipperType !== 'BROKER' &&
    hideCustomerInvoiced &&
    !customer_payment.received_date &&
    !customer_payment.terms &&
    !customer_payment.method &&
    !customer_payment.amount &&
    !customer_payment.reference_number &&
    !customer_payment.notes;

  const showCarrierRequestedSuperPayNudging =
    shipperType === 'BROKER' &&
    !!order.invoice?.is_carrier_requested_superpay &&
    !isSuperpayPayment(order.payment) &&
    (order.status === 'invoiced' || order.status === 'delivery_verified');

  const paymentTerm = useMemo(() => {
    if (!payment.terms) {
      return 'No Info';
    }
    return formatPaymentTerm(payment.terms);
  }, [payment.terms]);

  const { isAdvancedTier } = useProductTiers();

  const handleCollapse = () => {
    writeStorageItem(
      PAYMENT_CARD_STATE_KEY,
      expanded ? 'collapsed' : 'expanded',
    );
    toggleExpand();
  };

  return (
    <div aria-labelledby={uid}>
      <GridBox mb={2}>
        <Typography id={uid} variant="h3">
          Payment
        </Typography>
      </GridBox>
      {showCarrierRequestedSuperPayNudging && (
        <GridBox my={2}>
          <CarrierRequestedSuperPay source="Order View" order={order} />
        </GridBox>
      )}
      {hideMainSection && hideCarrierSection && hideCustomerSection && (
        <NoData my={3} text="No payment info yet" />
      )}
      {!hideMainSection && (
        <DefinitionList flexDirection="column" mb={2}>
          <DefinitionListRowLegacy
            my={2}
            label="Total Tariff"
            value={<Price value={totalTariff} />}
            hidden={!totalTariff}
          />

          <DefinitionListRowLegacy
            my={2}
            label="Deposit"
            value={<Price value={deposit} />}
            hidden={!deposit || shipperType === 'CUSTOMER'}
          />

          <DefinitionListRowLegacy
            my={2}
            label="Invoiced"
            value={<OrderViewCustomerInvoice order={order} />}
            hidden={hideCustomerInvoiced || shipperType === 'BROKER'}
          />

          <DefinitionListRowLegacy
            mt={2}
            label="Total"
            value={
              <Price
                value={totalCarrierPrice}
                style={{
                  color: isOrderBelowRecommendedPrice(order)
                    ? ColorDynamic.Yellow500
                    : undefined,
                }}
              />
            }
            hidden={!totalCarrierPrice}
          />

          <DefinitionListRowLegacy
            mb={2}
            label="Carrier Price"
            value={<OrderViewDistanceWithPrice order={order} />}
            withGuide={false}
            hidden={!totalCarrierPrice || shipperType !== 'BROKER'}
          />

          <ExpeditedPay order={order} />

          <OrderViewPaymentRecommendation order={order} />

          <DefinitionListRowLegacy
            my={2}
            label="Due from Carrier"
            value={<Price value={dueFromCarrier} />}
            hidden={!dueFromCarrier}
          />

          <DefinitionListRowLegacy
            my={2}
            label="Gross Profit"
            value={
              <Flex>
                <Price value={grossProfit} />
                &nbsp;
                <Typography variant="body2" color="textSecondary">
                  {grossProfitPercentage &&
                    `(${grossProfitPercentage.toFixed()}%)`}
                </Typography>
              </Flex>
            }
            hidden={!totalCarrierPrice || !totalTariff}
          />
        </DefinitionList>
      )}
      {priceNegotiation && priceNegotiationInfo && (
        <DefinitionList flexDirection="column" py={2}>
          <Stack space="xsmall">
            <Typography variant="h5">Price Negotiation</Typography>

            <DefinitionListRowLegacy
              my={2}
              label="Negotiation Budget"
              value={<Price value={priceNegotiation.negotiation_budget} />}
            />
          </Stack>

          <DefinitionListRowLegacy
            my={2}
            label="Acceptable Bid"
            value={
              <Stack>
                {priceNegotiationInfo.currentIteration > 0 ? (
                  <Inline space="xxsmall">
                    {priceNegotiation.increase_every != null &&
                      priceNegotiation.increase_by_percent != null && (
                        <Typography color="textSecondary">
                          Day {priceNegotiationInfo.currentDay} ·
                        </Typography>
                      )}
                    <Price value={priceNegotiationInfo.currentPrice} />
                  </Inline>
                ) : (
                  <Price value={order.price} />
                )}
              </Stack>
            }
          />

          {priceNegotiationInfo.maxDate.startOf('day') <= today &&
            priceNegotiation.increase_by_percent !== null &&
            priceNegotiation.increase_every !== null && (
              <DefinitionListRowLegacy
                label={
                  <Typography variant="caption" color="textSecondary">
                    Max bid reached
                  </Typography>
                }
                value={
                  <Link
                    underline="none"
                    variant="caption"
                    color="primary"
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsOpenPriceNegotiationDrawer(true);
                    }}
                  >
                    Update Negotiation
                  </Link>
                }
              />
            )}
        </DefinitionList>
      )}
      <Collapse in={expanded}>
        {!hideCarrierSection && (
          <DefinitionList flexDirection="column" py={2}>
            <Typography variant="h4">Carrier</Typography>

            <DefinitionListRowLegacy
              my={2}
              label="Invoiced"
              value={
                invoice?.url &&
                invoice.date && (
                  <Link href={invoice.url} target="_blank" rel="noreferrer">
                    <DetailedFormattedDate date={invoice.date} />
                  </Link>
                )
              }
              hidden={hideCarrierInvoiced}
            />

            <DefinitionListRowLegacy
              my={2}
              label="Marked as Invoiced"
              value={
                invoice?.adjusted_invoice_date && (
                  <DetailedFormattedDate date={invoice.adjusted_invoice_date} />
                )
              }
              hidden={!invoice || !invoice.adjusted_invoice_date}
            />

            <DefinitionListRowLegacy
              my={2}
              label="Sent Date"
              value={
                payment.sent_date && (
                  <DetailedFormattedDate date={payment.sent_date} />
                )
              }
              hidden={!payment.sent_date}
            />

            <SuperPayDates payment={payment} />

            <DefinitionListRowLegacy
              my={2}
              aria-label="Carrier Payment Terms"
              label="Terms"
              value={paymentTerm}
              hidden={!payment.terms}
            />

            <DefinitionListRowLegacy
              my={2}
              label="Method"
              aria-label="Carrier Paid Method"
              value={
                payment.method ? (
                  payment.method === 'superpay' ? (
                    <SuperPayLogo />
                  ) : (
                    formatPaymentMethod(payment.method)
                  )
                ) : (
                  'No Info'
                )
              }
              hidden={!payment.method}
            />

            <DefinitionListRowLegacy
              my={2}
              label="Amount"
              value={<Price value={payment.amount} />}
              hidden={!payment.amount}
            />

            <DefinitionListRowLegacy
              my={2}
              label="Ref #"
              value={payment.reference_number}
              hidden={!payment.reference_number}
            />

            <DefinitionListColumnLegacy
              my={2}
              label="Carrier Payment Notes"
              value={<OrderViewNote mt={1} notes={payment.notes} />}
              hidden={!payment.notes}
            />
          </DefinitionList>
        )}
        {!hideCustomerSection && isAdvancedTier && (
          <DefinitionList flexDirection="column" py={2}>
            <Typography variant="h4">Customer</Typography>

            <DefinitionListRowLegacy
              my={2}
              label="Invoiced"
              value={<OrderViewCustomerInvoice order={order} />}
              hidden={hideCustomerInvoiced}
            />

            <DefinitionListRowLegacy
              my={2}
              label="Received Date"
              value={
                customer_payment.received_date && (
                  <DetailedFormattedDate
                    date={customer_payment.received_date}
                  />
                )
              }
              hidden={!customer_payment.received_date}
            />

            <DefinitionListRowLegacy
              my={2}
              label="Terms"
              aria-label="Customer Payment Terms"
              value={
                customer_payment.terms
                  ? formatPaymentTerm(customer_payment.terms)
                  : 'No Info'
              }
              hidden={!customer_payment.terms}
            />

            <DefinitionListRowLegacy
              my={2}
              aria-label="Customer Paid Method"
              label="Method"
              value={
                customer_payment.method ? (
                  customer_payment.method === 'superpay' ? (
                    <SuperPayLogo />
                  ) : (
                    formatPaymentMethod(customer_payment.method, {
                      fallback: startCase(customer_payment.method),
                    })
                  )
                ) : (
                  'No Info'
                )
              }
              hidden={!customer_payment.method}
            />

            <DefinitionListRowLegacy
              my={2}
              label="Amount"
              value={<Price value={customer_payment.amount} />}
              hidden={!customer_payment.amount}
            />

            <DefinitionListRowLegacy
              my={2}
              label="Ref #"
              value={customer_payment.reference_number}
              hidden={!customer_payment.reference_number}
            />

            <DefinitionListColumnLegacy
              my={2}
              label="Customer Payment Notes"
              value={<OrderViewNote mt={1} notes={customer_payment.notes} />}
              hidden={!customer_payment.notes}
            />
          </DefinitionList>
        )}
      </Collapse>
      {!hideCarrierSection && !hideCustomerSection && (
        <>
          {isAdvancedTier && <Divider mb={3} />}

          <CollapseButton expanded={expanded} onClick={handleCollapse} />
        </>
      )}
      <PriceNegotiationDrawer
        source="Order View"
        onClose={() => {
          setIsOpenPriceNegotiationDrawer(false);
        }}
        order={isOpenPriceNegotiationDrawer ? order : undefined}
        onSubmitSuccess={() => {
          setIsOpenPriceNegotiationDrawer(false);
        }}
      />
    </div>
  );
};

interface SuperPayDatesProps {
  payment: OrderPayment;
}
function SuperPayDates({ payment }: SuperPayDatesProps) {
  const superPay = payment.super_pay;

  if (!isSuperpayPayment(payment) || !superPay) return null;

  return superPay.paid_at ? (
    <DefinitionListRowLegacy
      my={2}
      label="Paid"
      value={<DetailedFormattedDate date={superPay.paid_at} />}
    />
  ) : (
    <>
      {!!superPay.scheduled_for && (
        <DefinitionListRowLegacy
          my={2}
          label="Charge Scheduled"
          value={<DetailedFormattedDate date={superPay.scheduled_for} />}
        />
      )}
      {!!superPay.expected_at && (
        <DefinitionListRowLegacy
          my={2}
          label="Deposit Expected"
          value={<DetailedFormattedDate date={superPay.expected_at} />}
        />
      )}
    </>
  );
}

function ExpeditedPay({ order }: { order: Order }) {
  const isSuperPayExpeditedPayEnabled = useFeatureToggle(
    'payments.expedited-pay.access.ui',
  );
  const { price = 0, payment } = order;
  const expeditedFeeAmount = payment?.super_pay?.expedited_pay_fee_amount || 0;

  const { expeditedFeePercent, expeditedTotalPrice } = useMemo(() => {
    const isExpeditedPay = !!(
      price &&
      expeditedFeeAmount &&
      isSuperPayExpeditedPayEnabled
    );

    const feeValue = isExpeditedPay ? (expeditedFeeAmount * 100) / price : 0;
    const remainsPrice = isExpeditedPay ? price - expeditedFeeAmount : 0;

    return {
      expeditedFeePercent: round(feeValue),
      expeditedTotalPrice: remainsPrice,
    };
  }, [expeditedFeeAmount, isSuperPayExpeditedPayEnabled, price]);

  if (!expeditedFeePercent || !expeditedTotalPrice) {
    return null;
  }

  return (
    <Box marginTop="xsmall" marginBottom="xsmall">
      <Stack space="small">
        <DescriptionLineItem
          title={<Typography color="textSecondary">Expedite Fee</Typography>}
        >
          <Tooltip
            placement="top-end"
            interactive={true}
            title={
              <>
                Fee carrier is charged
                <br />
                for expediting payment
              </>
            }
          >
            <Box>
              <Inline space="xxsmall">
                <Typography>
                  {formatCurrency(expeditedFeeAmount, {
                    minimumFractionDigits: 0,
                  })}
                </Typography>
                <Typography color="textSecondary">
                  ({expeditedFeePercent}%)
                </Typography>
              </Inline>
            </Box>
          </Tooltip>
        </DescriptionLineItem>
        <DescriptionLineItem
          title={<Typography color="textSecondary">Charge Amount</Typography>}
        >
          <Tooltip
            placement="top-end"
            interactive={true}
            title={
              <>
                Amount withdrawn
                <br />
                from your account
              </>
            }
          >
            <Typography>
              {formatCurrency(expeditedTotalPrice, {
                minimumFractionDigits: 0,
              })}
            </Typography>
          </Tooltip>
        </DescriptionLineItem>
      </Stack>
    </Box>
  );
}
