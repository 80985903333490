import { Tooltip } from '@material-ui/core';
import {
  Column,
  Columns,
  Inline,
  Stack,
  Tag,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { PricePerMile } from 'core/PricePerMile';
import { OrderRequestDTO } from 'orders/data/dto/OrderRequestDTO';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useOrderCountsCache } from 'shared/api/OrderCountAPI';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import styled from 'styled-components';
import { OrderRequestActions } from './OrderRequestActions';
import { OrderRequestCarrierDetails } from './OrderRequestCarrierDetails';
import { OrderRequestStatusDetails } from './OrderRequestStatusDetails';

const OrderRequestPriceContainer = styled.div`
  padding-left: 20px;
`;

interface OrderRequestProps {
  order: Order;
  request: OrderRequestDTO;
  onSubmitSuccess: (order: Order) => void;
}

export function OrderRequest({
  order,
  request,
  onSubmitSuccess,
}: OrderRequestProps) {
  const screen = useResponsiveValue('mobile', 'mobile', 'desktop');
  const { invalidateOrderCounts } = useOrderCountsCache();
  const [isSuperPay, setSuperPay] = useState<boolean>(false);
  const [counterOfferPrice, setCounterOfferPrice] = useState<number>();

  const requestPrice = request.counter_offer_price || request.price;
  const changedAmount = Number(requestPrice) - Number(order.price);

  if (screen === 'mobile') {
    return (
      <Stack space="small">
        <Columns>
          <Column>
            <Inline space="xxsmall" verticalAlign="center">
              <TextBox variant="heading-3">
                {formatCurrency(requestPrice, {
                  maximumFractionDigits: 0,
                })}
              </TextBox>
              {!!changedAmount && (
                <Tooltip title="Carrier is requesting a change in price">
                  <Tag color="yellow" variant="subtle">
                    <NumericFormat
                      displayType="text"
                      value={changedAmount}
                      thousandSeparator={true}
                      decimalScale={2}
                      prefix="$"
                      suffix={changedAmount > 0 ? ' more' : ' less'}
                    />
                  </Tag>
                </Tooltip>
              )}
            </Inline>
          </Column>
          <Column width="content">
            <TextBox color="secondary">
              <PricePerMile order={order} price={request.price} />
            </TextBox>
          </Column>
        </Columns>

        <Stack space="xxsmall">
          <Inline space="xxsmall" verticalAlign="center">
            <TextBox variant="heading-4">
              <DetailedFormattedDate
                variant="ShortDate"
                date={request.pickup_date}
              />
            </TextBox>
            &#xb7;
            <TextBox color="secondary">Pickup</TextBox>
          </Inline>
          <Inline space="xxsmall" verticalAlign="center">
            <TextBox variant="heading-4">
              <DetailedFormattedDate
                variant="ShortDate"
                date={request.delivery_date}
              />
            </TextBox>
            &#xb7;
            <TextBox color="secondary">Delivery</TextBox>
          </Inline>
        </Stack>

        <OrderRequestStatusDetails request={request} />
        <OrderRequestCarrierDetails
          order={order}
          request={request}
          isSuperPay={isSuperPay}
          setSuperPay={setSuperPay}
          isSuperPayDisabled={!!counterOfferPrice}
        />

        {(request.status === 'OFFER_SENT' ||
          request.status === 'COUNTER_OFFER_SENT' ||
          request.status === 'REQUEST_PENDING') && (
          <Box width={['100%', '100%', '180px']}>
            <OrderRequestActions
              order={order}
              request={request}
              isSuperPay={isSuperPay}
              setSuperPay={setSuperPay}
              counterOfferPrice={counterOfferPrice}
              setCounterOfferPrice={setCounterOfferPrice}
              hasPrevCounterOffer={request.status === 'COUNTER_OFFER_SENT'}
              onSubmitSuccess={(requestedOrder) => {
                void invalidateOrderCounts();
                onSubmitSuccess(requestedOrder);
              }}
            />
          </Box>
        )}
      </Stack>
    );
  }

  return (
    <Stack space="small">
      <OrderRequestStatusDetails request={request} />
      <Columns space="small" collapseBelow="desktop">
        <Column width="content">
          <Box width="330px">
            <OrderRequestCarrierDetails
              order={order}
              request={request}
              isSuperPay={isSuperPay}
              setSuperPay={setSuperPay}
              isSuperPayDisabled={!!counterOfferPrice}
            />
          </Box>
        </Column>

        <Column width="content">
          <Box width="180px">
            <Stack space="xxsmall">
              <Inline space="xxsmall" verticalAlign="center">
                <TextBox variant="heading-4">
                  <DetailedFormattedDate
                    variant="ShortDate"
                    date={request.pickup_date}
                  />
                </TextBox>
                &#xb7;
                <TextBox color="secondary">Pickup</TextBox>
              </Inline>
              <Inline space="xxsmall" verticalAlign="center">
                <TextBox variant="heading-4">
                  <DetailedFormattedDate
                    variant="ShortDate"
                    date={request.delivery_date}
                  />
                </TextBox>
                &#xb7;
                <TextBox color="secondary">Delivery</TextBox>
              </Inline>
            </Stack>
          </Box>
        </Column>

        <Column>
          <OrderRequestPriceContainer>
            <Stack space="xxsmall">
              <Stack space="none">
                <TextBox variant="heading-3">
                  {formatCurrency(requestPrice, {
                    maximumFractionDigits: 0,
                  })}
                </TextBox>

                {changedAmount !== 0 && (
                  <Tooltip title="Carrier is requesting a change in price">
                    <Tag color="yellow" variant="subtle">
                      <NumericFormat
                        displayType="text"
                        value={changedAmount}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix="$"
                        suffix={changedAmount > 0 ? ' more' : ' less'}
                      />
                    </Tag>
                  </Tooltip>
                )}
              </Stack>

              <TextBox color="secondary">
                <PricePerMile order={order} price={request.price} />
              </TextBox>
            </Stack>
          </OrderRequestPriceContainer>
        </Column>

        <Column width="content">
          <Box width={['100%', '100%', '180px']}>
            {(request.status === 'OFFER_SENT' ||
              request.status === 'COUNTER_OFFER_SENT' ||
              request.status === 'REQUEST_PENDING') && (
              <OrderRequestActions
                order={order}
                request={request}
                isSuperPay={isSuperPay}
                setSuperPay={setSuperPay}
                counterOfferPrice={counterOfferPrice}
                setCounterOfferPrice={setCounterOfferPrice}
                hasPrevCounterOffer={request.status === 'COUNTER_OFFER_SENT'}
                onSubmitSuccess={(requestedOrder) => {
                  void invalidateOrderCounts();
                  onSubmitSuccess(requestedOrder);
                }}
              />
            )}
          </Box>
        </Column>
      </Columns>
    </Stack>
  );
}
