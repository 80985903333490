import {
  isValidPaymentTerm,
  isValidSuperPayTerm,
  listPaymentTerms,
  OTHER_PAYMENT_TERMS,
  PaymentMethod,
  PaymentTerm,
  SUPERPAY_TERMS,
} from '@superdispatch/sdk';
import { useCallback, useMemo } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { CarrierPaymentDTO } from 'shared/dto/Order/CarrierPaymentDTO';
import Order, { OrderPayment } from 'shared/types/order';
import { OrderFormValues } from '../orders/core/form/OrderForm';
import { useShipperProfile } from '../shipper-profile/data/ShipperProfileAPI';

export function isSuperpayPayment(payment?: CarrierPaymentDTO | OrderPayment) {
  return payment?.method === 'superpay';
}

export function isSuperPayStatusAvailable(
  values: OrderFormValues | Order | undefined,
) {
  if (!values || !values.payment || !values.guid) {
    return false;
  }

  const { payment } = values;
  return payment.method === 'superpay' && !!payment.super_pay?.status;
}

export function usePaymentTermUtils() {
  const isMatchPaymentTermsToMethodsEnabled = useFeatureToggle(
    'match-payment-terms-to-methods.enabled.ui',
  );

  const getPaymentTerms = useCallback(
    (method?: PaymentMethod | null, terms?: PaymentTerm | null) => {
      if (method === 'superpay') {
        return SUPERPAY_TERMS;
      }

      if (!!method && isMatchPaymentTermsToMethodsEnabled) {
        const paymentTerms = Array.from(listPaymentTerms(method));

        // add terms to preserve current selection if it's not in the list
        if (terms && !paymentTerms.includes(terms)) {
          paymentTerms.push(terms);
        }

        return paymentTerms;
      }

      return OTHER_PAYMENT_TERMS;
    },
    [isMatchPaymentTermsToMethodsEnabled],
  );

  const getValidPaymentTerm = useCallback(
    (paymentMethod: PaymentMethod, paymentTerm: PaymentTerm) => {
      if (paymentMethod === 'superpay' && !isValidSuperPayTerm(paymentTerm)) {
        return '1_3_days';
      }
      if (
        !isValidPaymentTerm(paymentTerm) ||
        (isMatchPaymentTermsToMethodsEnabled &&
          !listPaymentTerms(paymentMethod).includes(paymentTerm))
      ) {
        return '';
      }
      return paymentTerm;
    },
    [isMatchPaymentTermsToMethodsEnabled],
  );

  return { getPaymentTerms, getValidPaymentTerm };
}

export function usePaymentDefault() {
  const { data: shipper } = useShipperProfile({ refetchOnWindowFocus: false });

  const paymentDefaultMethod = shipper?.default_payment_method || 'other';

  const paymentDefaultTerm = useMemo(() => {
    if (
      paymentDefaultMethod === 'superpay' &&
      !shipper?.default_payment_terms
    ) {
      return '1_3_days';
    }

    return shipper?.default_payment_terms || 'other';
  }, [paymentDefaultMethod, shipper?.default_payment_terms]);

  return { paymentDefaultMethod, paymentDefaultTerm };
}
