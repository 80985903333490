import {
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Color,
  ColorDynamic,
  Column,
  Columns,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { plainToClass } from 'class-transformer';
import { CarrierTags, UnverifiedTag, VerifiedTag } from 'core/CarrierTags';
import { DictionaryCarrierDTO } from 'core/dictionary/data/DictionaryDTO';
import { isCarrierInsuranceExpired } from 'core/insurance/ExpirationDates';
import { RatingStats } from 'core/ratings/RatingStats';
import { TotalOrdersDispatchedLink } from 'core/TotalOrdersDispatchedLink';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { trackEvent, trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { Carrier, CarrierBrokerPreferences } from 'shared/types/carrier';
import Order from 'shared/types/order';
import { TextOverflow } from 'shared/ui/TextOverflow';
import styled from 'styled-components';
import { CarrierAcceptsSuperPay } from 'superpay/CarrierAcceptsSuperPay';
import { CarrierRequestsSuperPay } from '../../../../superpay/CarrierRequestsSuperPay';
import { EmptyNetworkCarrier } from '../EmptyNetworkCarrier';

const StyledContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  overflow: auto;
  flex-direction: column;
  padding: ${({ isMobile }) => (isMobile ? '0 16px 16px' : '0 32px 16px')};
`;

const StyledSpinner = styled(CircularProgress)`
  margin: auto;
`;

const isTagsRowAvailable = (broker_records: CarrierBrokerPreferences) => {
  const { preferred, approved, insurance_certificate_holder, in_blacklist } =
    broker_records;
  return (
    preferred ||
    approved ||
    insurance_certificate_holder ||
    in_blacklist ||
    isCarrierInsuranceExpired(broker_records.insurance_expires_at)
  );
};

interface CarrierNetworkTabProps {
  onSelect: (carrier: Carrier) => void;
  selectedCarrierGuid?: string;
  carriers?: Carrier[];
  isLoading?: boolean;
  tabName: 'suggested' | 'previously_dispatched' | 'partner';
  order?: Order;
}

const StyledBox = styled(Box)<{ active: boolean }>`
  border: 1px solid ${ColorDynamic.Silver500};
  border-radius: 4px;
  cursor: pointer;

  ${({ active }) => active && `border: 2px solid ${Color.Blue300};`};
`;

const StyledTableRow = styled(TableRow)`
  &:first-child {
    .MuiTableCell-root {
      padding-top: 16px;
    }
  }
`;

const StyledTableCell = styled(TableCell)`
  padding: 8px 0;
  border: none;
`;

const StyledBoxWrap = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`;

const StyledTextOverflow = styled(TextOverflow)`
  margin-right: 8px;
`;

export function CarrierNetworkTab({
  onSelect,
  selectedCarrierGuid,
  carriers,
  isLoading,
  tabName,
}: CarrierNetworkTabProps) {
  const isCarrierRequestsSuperPay = useFeatureToggle(
    'carrier-requests-superpay.access.ui',
  );
  const handleSelect = (carrier: Carrier) => () => onSelect(carrier);
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = platform === 'mobile';

  return (
    <Stack space="none">
      <StyledContainer isMobile={isMobile}>
        {isLoading ? (
          <StyledSpinner variant="indeterminate" color="primary" size={40} />
        ) : carriers && carriers.length === 0 ? (
          <EmptyNetworkCarrier tabName={tabName} />
        ) : (
          <TableContainer>
            <Table>
              <TableBody>
                {carriers?.map((carrier) => (
                  <StyledTableRow key={carrier.guid}>
                    <StyledTableCell
                      aria-label="select carrier"
                      onClick={handleSelect(carrier)}
                    >
                      <StyledBox
                        paddingLeft="small"
                        paddingRight="small"
                        paddingTop="small"
                        paddingBottom="small"
                        active={selectedCarrierGuid === carrier.guid}
                      >
                        <Stack space="none">
                          <Columns space="xsmall" collapseBelow="desktop">
                            <Column width="content">
                              <StyledBoxWrap>
                                <StyledTextOverflow size={280}>
                                  {tabName === 'partner' ? (
                                    <Typography variant="h5">
                                      {carrier.name}
                                    </Typography>
                                  ) : (
                                    <Link
                                      component={RouterLink}
                                      to={parseURITemplate(
                                        `/manage-carriers/${carrier.guid}{?utm_medium,utm_content}`,
                                        {
                                          utm_medium:
                                            'Single Send Offer Drawer',
                                          utm_content:
                                            tabName === 'previously_dispatched'
                                              ? 'Previously Dispatched'
                                              : 'Suggested',
                                        },
                                      )}
                                      target="_blank"
                                      onClick={() =>
                                        trackEventLegacy(
                                          tabName === 'suggested'
                                            ? 'Opened Suggested Carrier Profile'
                                            : 'Opened Previously Dispatched Carrier Profile',
                                        )
                                      }
                                    >
                                      <Typography variant="h5">
                                        {carrier.name}
                                      </Typography>
                                    </Link>
                                  )}
                                </StyledTextOverflow>

                                {tabName !== 'partner' ? (
                                  carrier.is_super ? (
                                    <VerifiedTag />
                                  ) : (
                                    <UnverifiedTag />
                                  )
                                ) : null}
                              </StyledBoxWrap>
                            </Column>

                            {carrier.carrier_type !== 'INTERNAL' &&
                              tabName !== 'partner' && (
                                <Column width="content">
                                  <RatingStats
                                    ratingStats={carrier.rating_stats}
                                    carrierGuid={carrier.guid}
                                  />
                                </Column>
                              )}
                          </Columns>

                          {isCarrierRequestsSuperPay &&
                          carrier.broker_records?.superpay_requested ? (
                            <Box paddingTop="xxsmall">
                              <CarrierRequestsSuperPay
                                source="Direct Offer Previously Dispatched"
                                compact={true}
                              />
                            </Box>
                          ) : (
                            carrier.is_super_pay_enabled && (
                              <Box paddingTop="xxsmall">
                                <CarrierAcceptsSuperPay
                                  source="Direct Offer Previously Dispatched"
                                  compact={true}
                                />
                              </Box>
                            )
                          )}
                          <div>
                            {carrier.broker_records &&
                              isTagsRowAvailable(carrier.broker_records) && (
                                <Box paddingTop="small">
                                  <CarrierTags
                                    carrierBrokerPreferences={
                                      carrier.broker_records
                                    }
                                  />
                                </Box>
                              )}

                            <Box paddingTop="xsmall">
                              <TotalOrdersDispatchedLink
                                carrier={plainToClass(
                                  DictionaryCarrierDTO,
                                  carrier,
                                )}
                                source="load_offer_drawer"
                                loadsCount={
                                  carrier.broker_records?.accepted_loads_count
                                }
                              />
                            </Box>
                          </div>
                        </Stack>
                      </StyledBox>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledContainer>
    </Stack>
  );
}

interface SuggestedCarrierNetworkTabProps extends CarrierNetworkTabProps {
  isLoading: boolean;
  carriers: Carrier[];
  previouslyDispatchedCarriers: Carrier[];
}

export function SuggestedCarrierNetworkTab({
  onSelect,
  selectedCarrierGuid,
  tabName,
  carriers,
  isLoading,
  order,
  previouslyDispatchedCarriers,
}: SuggestedCarrierNetworkTabProps) {
  const isCarrierRequestsSuperPay = useFeatureToggle(
    'carrier-requests-superpay.access.ui',
  );
  const handleSelect = (carrier: Carrier) => () => onSelect(carrier);
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = platform === 'mobile';

  useEffect(() => {
    if (!isLoading) {
      trackEvent('Shipper Opened Suggested Carriers Tab', {
        suggested_carriers: JSON.stringify(
          carriers.map((c) => ({ guid: c.guid, rating: c.rating_stats })),
        ),
        order_guid: order?.guid,
      });
    }
  }, [carriers, order?.guid, isLoading]);

  return (
    <Stack space="none">
      <StyledContainer isMobile={isMobile}>
        {isLoading ? (
          <StyledSpinner variant="indeterminate" color="primary" size={40} />
        ) : carriers.length === 0 ? (
          <EmptyNetworkCarrier tabName={tabName} />
        ) : (
          <TableContainer>
            <Table>
              <TableBody>
                {carriers.map((carrier) => (
                  <StyledTableRow key={carrier.guid}>
                    <StyledTableCell
                      aria-label="select carrier"
                      onClick={handleSelect(carrier)}
                    >
                      <StyledBox
                        paddingLeft="small"
                        paddingRight="small"
                        paddingTop="small"
                        paddingBottom="small"
                        active={selectedCarrierGuid === carrier.guid}
                      >
                        <Stack space="none">
                          <Columns space="xsmall" collapseBelow="desktop">
                            <Column width="content">
                              <StyledBoxWrap>
                                <StyledTextOverflow size={280}>
                                  <Link
                                    component={RouterLink}
                                    to={parseURITemplate(
                                      `/manage-carriers/${carrier.guid}/{?utm_medium,utm_content}`,
                                      {
                                        utm_medium: 'Single Send Offer Drawer',
                                        utm_content: 'Suggested',
                                      },
                                    )}
                                    target="_blank"
                                    onClick={() =>
                                      trackEventLegacy(
                                        tabName === 'suggested'
                                          ? 'Opened Suggested Carrier Profile'
                                          : 'Opened Previously Dispatched Carrier Profile',
                                        {
                                          is_previous_dispatched_carrier:
                                            previouslyDispatchedCarriers.some(
                                              (c) => c.guid === carrier.guid,
                                            ),
                                        },
                                      )
                                    }
                                  >
                                    <Typography variant="h5">
                                      {carrier.name}
                                    </Typography>
                                  </Link>
                                </StyledTextOverflow>
                                {carrier.is_super ? (
                                  <VerifiedTag />
                                ) : (
                                  <UnverifiedTag />
                                )}
                              </StyledBoxWrap>
                            </Column>

                            {carrier.carrier_type !== 'INTERNAL' && (
                              <Column width="content">
                                <RatingStats
                                  ratingStats={carrier.rating_stats}
                                  carrierGuid={carrier.guid}
                                />
                              </Column>
                            )}
                          </Columns>

                          {isCarrierRequestsSuperPay &&
                          carrier.broker_records?.superpay_requested ? (
                            <Box paddingTop="xxsmall">
                              <CarrierRequestsSuperPay
                                source="Direct Offer Suggested"
                                compact={true}
                              />
                            </Box>
                          ) : (
                            carrier.is_super_pay_enabled && (
                              <Box paddingTop="xxsmall">
                                <CarrierAcceptsSuperPay
                                  source="Direct Offer Suggested"
                                  compact={true}
                                />
                              </Box>
                            )
                          )}

                          <div>
                            {carrier.broker_records &&
                              isTagsRowAvailable(carrier.broker_records) && (
                                <Box paddingTop="small">
                                  <CarrierTags
                                    carrierBrokerPreferences={
                                      carrier.broker_records
                                    }
                                  />
                                </Box>
                              )}
                            {!!carrier.broker_records?.accepted_loads_count && (
                              <Box paddingTop="xsmall">
                                <TotalOrdersDispatchedLink
                                  carrier={plainToClass(
                                    DictionaryCarrierDTO,
                                    carrier,
                                  )}
                                  source="load_offer_drawer"
                                  loadsCount={
                                    carrier.broker_records.accepted_loads_count
                                  }
                                />
                              </Box>
                            )}
                          </div>
                        </Stack>
                      </StyledBox>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledContainer>
    </Stack>
  );
}
