import { ThumbDown, ThumbUp } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import {
  ColorDynamic,
  Column,
  Columns,
  InfoTooltip,
  Inline,
  Stack,
  Tag,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { CarrierFullInfo } from 'shared/types/carrier';
import styled from 'styled-components';
import { CarrierRatingDTO } from './data/CarrierRatingDTO';
import { RatingResponse } from './RatingResponse';

// TODO add white-space option to TextBox
const RawText = styled(TextBox)`
  white-space: break-spaces;
`;

const IconWrapper = styled.div`
  padding: 12px;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &[data-variant='success'] {
    color: ${ColorDynamic.Green300};
    background-color: ${ColorDynamic.Green50};
  }

  &[data-variant='error'] {
    color: ${ColorDynamic.Red300};
    background-color: ${ColorDynamic.Red50};
  }
`;
interface CarrierRatingProps {
  rating: CarrierRatingDTO;
  name: string;
  carrier: CarrierFullInfo;
  showRatingDetails?: boolean;
  ratingActions?: ReactNode;
}
export function CarrierRating({
  rating,
  name,
  carrier,
  showRatingDetails = true,
  ratingActions,
}: CarrierRatingProps) {
  return (
    <Stack space="small">
      <Columns space="small" align="top">
        <Column width="content">
          {rating.rating === 'POSITIVE' ? (
            <IconWrapper data-variant="success">
              <ThumbUp color="inherit" fontSize="small" />
            </IconWrapper>
          ) : (
            <IconWrapper data-variant="error">
              <ThumbDown color="inherit" fontSize="small" />
            </IconWrapper>
          )}
        </Column>

        <Column>
          <Stack>
            <Stack space="small">
              <Stack space="xxsmall">
                <Inline aria-label="shipper name" verticalAlign="center">
                  <TextBox>{name}</TextBox>
                  {rating.status === 'PENDING' && (
                    <Inline verticalAlign="center" space="xxsmall">
                      <Tag
                        variant="subtle"
                        color="purple"
                        aria-label="Pending Tag"
                      >
                        Pending
                      </Tag>
                      <InfoTooltip
                        title={
                          <>
                            Pending negative rating status is visible to all
                            companies, but does not contain any details.
                            <br />
                            <br />
                            It will be published after 10 days to allow both
                            companies to resolve the issue.
                          </>
                        }
                      />
                    </Inline>
                  )}
                </Inline>
                <TextBox
                  display="block"
                  variant="caption"
                  color="secondary"
                  aria-label="rated at"
                >
                  <FormattedDate
                    variant="Date"
                    format="JodaISO"
                    date={rating.rated_at}
                  />
                </TextBox>
              </Stack>
              {showRatingDetails && rating.tags.length > 0 && (
                <Inline aria-label="tags">
                  {(
                    [
                      'ON_TIME_TRANSPORT',
                      'GOOD_COMMUNICATION',
                      'GOOD_CUSTOMER_SERVICE',
                      'LATE_TRANSPORT',
                      'DAMAGE',
                      'BAD_COMMUNICATION',
                      'BAD_CUSTOMER_SERVICE',
                      'LATE_CANCELLATION_OR_NO_SHOW',
                      'DOUBLE_BROKERING',
                    ] as const
                  ).map((tag) =>
                    !rating.tags.includes(tag) ? null : (
                      <Tag key={tag} variant="subtle" color="grey">
                        {CarrierRatingDTO.formatRatingTag(tag)}
                      </Tag>
                    ),
                  )}
                </Inline>
              )}
            </Stack>

            {showRatingDetails && !!rating.comment && (
              <Box maxWidth="680px">
                <RawText color="secondary" aria-label="comment">
                  {rating.comment}
                </RawText>
              </Box>
            )}
          </Stack>
        </Column>
        {ratingActions && <Column width="content">{ratingActions}</Column>}
      </Columns>
      {rating.response && rating.response_date && (
        <RatingResponse
          name={carrier.name}
          logo={carrier.logo}
          headerText={`Response from ${carrier.name}`}
          response={rating.response}
          responseDate={rating.response_date}
        />
      )}
    </Stack>
  );
}
