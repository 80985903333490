import { Typography } from '@material-ui/core';
import { ColorDynamic, ColorProp, Inline } from '@superdispatch/ui';
import { ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ bgColor: ColorProp }>`
  padding: 12px 16px;
  background-color: ${({ bgColor }) => ColorDynamic[bgColor]};
  border-bottom: 1px solid ${ColorDynamic.Silver500};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 16px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    gap: 16px;
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Icon = styled.div`
  color: ${ColorDynamic.Dark300};
  font-size: 20px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
`;

interface HeaderBannerProps {
  backgroundColor?: ColorProp;
  icon?: ReactNode;
  text: string;
  tag?: ReactNode;
  actions?: ReactNode;
}

export function HeaderBanner({
  backgroundColor = 'Silver200',
  icon,
  text,
  tag,
  actions,
}: HeaderBannerProps) {
  return (
    <Wrapper bgColor={backgroundColor}>
      <Container>
        <DetailsWrapper>
          <Inline verticalAlign="center" space="xsmall" noWrap={true}>
            <Icon>{icon}</Icon>
            <Typography>{text}</Typography>
          </Inline>
          {tag}
        </DetailsWrapper>
        <Actions>{actions}</Actions>
      </Container>
    </Wrapper>
  );
}
