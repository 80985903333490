import { Exclude, Expose } from 'class-transformer';
import { OrderTagDTO } from 'core/dictionary/data/OrdersTagAPI';

@Exclude()
export class GeneralSettingsDTO {
  @Expose() theme_mode: string;
  @Expose() time_zone: string;
  @Expose() bol_template: string;
  @Expose() inspection_type: string;
  @Expose() is_sso_enabled?: boolean;
  @Expose() is_sso_organization_created?: boolean;
  @Expose() is_sso_connection_active?: boolean;
  @Expose() domain?: string;
  @Expose() order_tag_settings: OrderTagDTO[];
  @Expose() max_number_of_tags: number;
}

@Exclude()
export class TimeZoneWithOffsetsDTO {
  @Expose() offset: string;
  @Expose() timezone: string;
  @Expose() is_pinned: boolean;
}

@Exclude()
export class WorkOSPortalLinkDTO {
  @Expose() url: string;
}

@Exclude()
export class RecoverPasswordDTO {
  @Expose() token?: string;
  @Expose() password: string;
}
