import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer as TableContainerMui,
  TableRow,
} from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { ColorDynamic } from '@superdispatch/ui';
import { OrderRequestDTO } from 'orders/data/dto/OrderRequestDTO';
import {
  useOrderRequestCount,
  useOrderRequests,
  useOrderRequestsAPI,
} from 'orders/data/OrderRequestsAPI';
import { useEffect, useMemo, useState } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { ChevronDown } from 'shared/icons/ChevronDown';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { OrderRequest } from './OrderRequest';
import { OrderRequestsHeader } from './OrderRequestsHeader';

const StyledTableCell = styled(TableCell)`
  padding: 32px 16px;
  border-bottom: 1px solid ${ColorDynamic.Silver400};

  @media (min-width: 768px) {
    padding: 32px;
  }
`;

const TableContainer = styled(TableContainerMui)`
  overflow-x: hidden;
`;

function useFilteredRequests(requests: OrderRequestDTO[] = []) {
  return useMemo(() => {
    const acceptedOffers = requests.filter(
      (request) => request.status === 'OFFER_ACCEPTED',
    );
    const waitingRequests = requests.filter(
      (request) =>
        request.status === 'REQUEST_PENDING' ||
        request.status === 'OFFER_SENT' ||
        request.status === 'COUNTER_OFFER_SENT',
    );
    const respondedRequests = requests.filter(
      (request) =>
        !acceptedOffers.includes(request) && !waitingRequests.includes(request),
    );

    return {
      acceptedOffers,
      waitingRequests,
      respondedRequests,
    };
  }, [requests]);
}

const REQUEST_SECTION_COLLAPSED_KEY = 'is_request_section_collapsed';

interface RequestSectionProps {
  order: Order;
  title: string;
  requests?: OrderRequestDTO[];
  isCollapsable?: boolean;
  showOrderDetails: boolean;
  onSubmitSuccess: (order: Order) => void;
}

function RequestSection({
  order,
  title,
  requests,
  isCollapsable,
  showOrderDetails,
  onSubmitSuccess,
}: RequestSectionProps) {
  const defaultCollapsed =
    useStorageValue(REQUEST_SECTION_COLLAPSED_KEY) === 'true';

  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  const setCollapsed = () => {
    setIsCollapsed(!isCollapsed);
    writeStorageItem(REQUEST_SECTION_COLLAPSED_KEY, String(!isCollapsed));
  };

  if (!requests?.length) return null;

  return (
    <>
      <OrderRequestsHeader
        order={order}
        title={title}
        showOrderDetails={showOrderDetails}
        isCollapsed={isCollapsed}
        setCollapsed={isCollapsable ? setCollapsed : undefined}
        collapseIcon={
          isCollapsable && (
            <IconButton size="small" onClick={setCollapsed}>
              <ChevronDown
                color="action"
                style={{
                  transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
              />
            </IconButton>
          )
        }
      />
      {requests.map((request) => (
        <TableRow
          key={request.guid}
          style={{
            display: isCollapsable && isCollapsed ? 'none' : 'table-row',
          }}
        >
          <StyledTableCell aria-label="select carrier">
            <OrderRequest
              order={order}
              request={request}
              onSubmitSuccess={onSubmitSuccess}
            />
          </StyledTableCell>
        </TableRow>
      ))}
    </>
  );
}

interface OrderRequestsListProps {
  order: Order;
  onSubmitSuccess: (order: Order) => void;
}

export function OrderRequestsList({
  order,
  onSubmitSuccess,
}: OrderRequestsListProps) {
  const { markRequestAsRead } = useOrderRequestsAPI();
  const { data: requests, refetch: refetchOrderRequests } = useOrderRequests(
    order.guid,
  );
  const { data: requestCount, refetch: refetchOrderRequestCount } =
    useOrderRequestCount(order.guid);

  const { acceptedOffers, waitingRequests, respondedRequests } =
    useFilteredRequests(requests?.objects);
  const isAllRequestsResponded =
    respondedRequests.length === requests?.objects.length;

  const { handleSubmit } = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => markRequestAsRead(order.guid),
  });

  const handleSubmitSuccess = (requestedOrder: Order) => {
    void refetchOrderRequestCount();
    void refetchOrderRequests();
    onSubmitSuccess(requestedOrder);
  };

  useEffect(() => {
    if (requestCount?.has_new_load_request) {
      handleSubmit();
    }
  }, [requestCount, handleSubmit]);

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <RequestSection
            order={order}
            requests={acceptedOffers}
            showOrderDetails={true}
            title="Accepted request"
            onSubmitSuccess={handleSubmitSuccess}
          />

          <RequestSection
            order={order}
            requests={waitingRequests}
            showOrderDetails={!acceptedOffers.length}
            onSubmitSuccess={handleSubmitSuccess}
            title={`${waitingRequests.length} ${formatPlural(
              waitingRequests.length,
              'request',
              'requests',
            )} waiting`}
          />

          <RequestSection
            order={order}
            isCollapsable={true}
            requests={respondedRequests}
            showOrderDetails={isAllRequestsResponded}
            onSubmitSuccess={handleSubmitSuccess}
            title={`${respondedRequests.length} responded ${formatPlural(
              respondedRequests.length,
              'request',
              'requests',
            )}`}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
