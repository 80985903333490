import { ColorDynamic } from '@superdispatch/ui';
import * as React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  flex-grow: 1;
  border-spacing: 0;
  border-collapse: separate;
`;

const StyledTableHead = styled.thead`
  border: none;
`;

const StyledTableRow = styled.tr`
  background: ${ColorDynamic.White};
`;

const StyledHeaderCell = styled.th`
  position: sticky;
  z-index: 2;
  top: 0;
  padding: 16px 16px;
  color: ${ColorDynamic.Dark100};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  vertical-align: middle;
  white-space: nowrap;
  background: inherit;
  border-bottom: 1px solid ${ColorDynamic.Silver400};
  text-align: left;

  > div,
  > label {
    display: inline-block;
    vertical-align: middle;
  }

  > label {
    margin-right: 5px;
    cursor: pointer;
  }

  &[data-sortable='true'] {
    cursor: pointer;
  }

  &[data-alignment='left'] {
    text-align: left;
  }
  &[data-alignment='right'] {
    text-align: right;
  }
`;

const StyledTableCell = styled.td`
  padding: 15px;
  color: ${ColorDynamic.Dark500};
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid ${ColorDynamic.Silver400};

  &[data-alignment='left'] {
    text-align: left;
  }
  &[data-alignment='right'] {
    text-align: right;
  }

  &[data-vertical-alignment='bottom'] {
    vertical-align: bottom;
  }
  &[data-vertical-alignment='middle'] {
    vertical-align: middle;
  }
  &[data-vertical-alignment='top'] {
    vertical-align: top;
  }
  &[data-vertical-alignment='baseline'] {
    vertical-align: baseline;
  }
`;

export const StyledSorter = styled.div`
  display: inline-block;
  vertical-align: middle;

  &:before,
  &:after {
    content: '';
    display: block;
    margin: 5px;
    border: solid transparent;
    border-width: 3px 4px;
    border-right-color: ${ColorDynamic.Dark500};
  }

  &:before {
    transform: rotate(90deg);
  }

  &:after {
    transform: rotate(-90deg);
  }

  &[data-sorted='true'] {
    &[data-order='ASC']:before {
      margin-top: 4px;
      border-width: 4px 5px;
      border-right-color: ${ColorDynamic.Dark100};
    }

    &[data-order='DESC']:after {
      margin-bottom: 4px;
      border-width: 4px 5px;
      border-right-color: ${ColorDynamic.Dark100};
    }
  }
`;

type Alignment = 'left' | 'right';
type VerticalAligment = 'bottom' | 'middle' | 'top' | 'baseline';

interface TableColumn {
  key: string | number;
  label?: React.ReactNode;
  content?: React.ReactNode;
  alignment?: Alignment;
  isSortable?: boolean;
  isVisible: boolean;
  sortKey?: string;
}

interface TableCell {
  key: string | number;
  content?: React.ReactNode;
  alignment?: Alignment;
  verticalAligment?: VerticalAligment;
}

interface TableRow extends React.HTMLAttributes<HTMLTableRowElement> {
  key: string | number;
  cells: TableCell[];
}

export interface TableConfig {
  columns: TableColumn[];
  rows: TableRow[];
}

export interface TableProps {
  config: TableConfig;
  sortKey?: string;
  sortOrder?: string;
  onSort?: (key: string, order: string) => void;
  className?: string;
}

/** @deprecated */
export const Table: React.ComponentType<TableProps> = ({
  config,
  sortKey,
  sortOrder,
  onSort,
  className,
}) => (
  <StyledTable className={className}>
    <StyledTableHead>
      <StyledTableRow>
        {config.columns
          .filter((column) => column.isVisible)
          .map((column, index) => (
            <StyledHeaderCell
              key={column.key}
              data-sortable={column.isSortable}
              data-alignment={column.alignment}
              data-testid={`table-content-${index + 1}`}
              onClick={() => {
                const { key } = column;
                const order =
                  sortKey === key
                    ? sortOrder === 'ASC'
                      ? 'DESC'
                      : 'ASC'
                    : 'ASC';
                if (column.isSortable) {
                  onSort?.(String(key), order);
                }
              }}
            >
              {column.label}
              <div>{column.content}</div>

              {column.isSortable && (
                <StyledSorter
                  data-sorted={sortKey === column.key}
                  data-order={sortOrder}
                />
              )}
            </StyledHeaderCell>
          ))}
      </StyledTableRow>
    </StyledTableHead>

    <tbody>
      {config.rows.map((row) => (
        <StyledTableRow {...row} key={row.key}>
          {row.cells
            .filter((_, idx) => config.columns[idx]?.isVisible)
            .map((cell) => (
              <StyledTableCell
                key={cell.key}
                data-alignment={cell.alignment}
                data-vertical-alignment={cell.verticalAligment}
                aria-label={String(cell.key)}
              >
                {cell.content}
              </StyledTableCell>
            ))}
        </StyledTableRow>
      ))}
    </tbody>
  </StyledTable>
);
