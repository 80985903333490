import { debounce } from '@material-ui/core';
import { Dehaze, ViewAgendaOutlined } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  ColorDynamic,
  Column,
  Columns,
  useResponsiveValue,
} from '@superdispatch/ui';
import { OrderListActions } from 'orders/core/actions/OrderListActions';
import {
  OrderListCardSortBar,
  OrdersListCardView,
} from 'orders/core/list/OrdersListCardView';
import { ORDER_VIEW_KEY } from 'orders/list/OrdersListPage';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { useQueryParams } from 'shared/helpers/RouteHelpers';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { OrderTable } from '../core';
import { OrderActions } from '../core/actions/OrderActions';
import { OrderActionsProvider } from '../core/actions/OrderActionsContext';
import { OrderBulkActions } from '../core/bulk-actions/OrderBulkActions';
import { OrderListTemplate } from '../core/list/OrderListTemplate';
import { OrdersListFooter } from '../core/list/OrdersListFooter';
import { OrdersListHeader } from '../core/list/OrdersListHeader';
import {
  DashboardOrdersType,
  useDashboardOrders,
} from '../data/DashboardOrderAPI';
import { DashboardOrderPageParamsDTO } from '../data/OrderListPageParamsDTO';

const Wrap = styled.div<{ collapse: boolean; height: string }>`
  min-height: ${({ height }) => height};
  box-sizing: border-box;
  background: ${ColorDynamic.White};
  transition: all 0.3s ease;
  opacity: 1;

  ${({ collapse }) =>
    collapse && 'height: 0; min-height: 0; opacity: 0; padding: 0;'};
`;

const StyledToggleButton = styled(ToggleButton)`
  color: ${ColorDynamic.Dark100};

  &.Mui-selected {
    background: transparent;
    color: ${ColorDynamic.Dark500};
  }
`;

const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const StyledColumns = styled(Columns)`
  padding: 16px;
`;

export function DashboardOrdersPage() {
  const { type } = useParams<{ type: string }>();
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [scrolDirection, setScrollDirection] = useState('up');
  const [params, setParams] = useQueryParams(DashboardOrderPageParamsDTO);
  const { data, refetch } = useDashboardOrders(
    type as DashboardOrdersType,
    params,
  );

  const orderViewKey = useStorageValue(ORDER_VIEW_KEY) || 'card';
  const [orderView, setOrderView] = useState<string>(orderViewKey);
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const selectedOrders = useMemo<Order[]>(() => {
    const orders = [];

    if (data) {
      for (let id of selectedIds) {
        const order = data.content.find((x) => x.id === id);

        if (order) {
          orders.push(order);
        }
      }
    }

    return orders;
  }, [selectedIds, data]);

  function toggleSelectedOrder(id: number) {
    const set = new Set(selectedIds);

    if (set.has(id)) {
      set.delete(id);
    } else {
      set.add(id);
    }
    setSelectedIds(Array.from(set));
  }

  const handleSetOrderView = (
    event: React.MouseEvent<HTMLElement>,
    newOrderView: string | null,
  ) => {
    if (event.target && newOrderView !== null) {
      writeStorageItem(ORDER_VIEW_KEY, newOrderView);
      setOrderView(newOrderView);
      trackEventLegacy('Clicked on change orders view', {
        selectedView: newOrderView,
      });
    }
  };

  const handleScroll = debounce((event) => {
    const { scrollTop } = event.target;

    const scrollMargin = Math.abs(scrollTop - lastScrollPosition);

    if (scrollTop >= lastScrollPosition && scrollMargin > 200) {
      setScrollDirection('down');
    }
    if (scrollTop < lastScrollPosition && scrollMargin > 200) {
      setScrollDirection('up');
    }

    if (scrollMargin > 200) {
      setLastScrollPosition(scrollTop);
    }
  }, 20);

  return (
    <OrderListTemplate
      header={
        <>
          <OrdersListHeader title="Orders" />

          <Wrap
            height={
              orderView !== 'card'
                ? 'unset'
                : platform === 'mobile'
                ? '80px'
                : '120px'
            }
            collapse={
              platform === 'mobile' &&
              orderView === 'card' &&
              scrolDirection === 'down'
            }
          >
            {platform !== 'mobile' && (
              <StyledColumns align="center">
                <Column width="fluid">
                  {selectedIds.length > 0 && (
                    <OrderBulkActions
                      orders={selectedOrders}
                      source="Dashboard Order List"
                      onActionComplete={() => refetch()}
                    />
                  )}
                </Column>
                <Column width="content">
                  <ToggleButtonGroup
                    exclusive={true}
                    value={orderView}
                    onChange={handleSetOrderView}
                    aria-label="order view toggle"
                  >
                    <StyledToggleButton value="card" aria-label="order list">
                      <ViewAgendaOutlined fontSize="small" />
                    </StyledToggleButton>
                    <StyledToggleButton value="table" aria-label="order table">
                      <Dehaze fontSize="small" />
                    </StyledToggleButton>
                  </ToggleButtonGroup>
                </Column>
              </StyledColumns>
            )}

            {orderView === 'card' && (
              <OrderListCardSortBar
                orders={data?.content || []}
                isLoading={!data}
                selectedOrders={selectedOrders}
                onSort={(key: string, order: string) =>
                  setParams({ sort: [key, order] })
                }
                sortKey={params.sort[0]}
                sortOrder={params.sort[1]}
                onSelectAllOrders={() => {
                  if (data) {
                    setSelectedIds(data.content.map((x) => x.id));
                  }
                }}
                onUnselectAllOrders={() => setSelectedIds([])}
              />
            )}
          </Wrap>
        </>
      }
      content={
        <OrderActionsProvider
          source="Dashboard Order List"
          onActionComplete={() => refetch()}
        >
          <Content onScroll={handleScroll}>
            {orderView === 'card' ? (
              <OrdersListCardView
                orders={data?.content || []}
                isLoading={!data}
                selectedOrders={selectedOrders}
                toggleSelectOrder={toggleSelectedOrder}
                renderActions={(order: Order) => (
                  <OrderActions order={order} source="Order List" />
                )}
              />
            ) : (
              <OrderTable
                searchQuery=""
                isLoading={!data}
                sortKey={params.sort[0]}
                sortOrder={params.sort[1]}
                orders={data?.content || []}
                selectedOrders={selectedOrders}
                onUnselectAllOrders={() => setSelectedIds([])}
                toggleSelectOrder={toggleSelectedOrder}
                renderActions={(order) => <OrderListActions order={order} />}
                onSort={(key, order) => setParams({ sort: [key, order] })}
                onSelectAllOrders={() => {
                  if (data) {
                    setSelectedIds(data.content.map((x) => x.id));
                  }
                }}
              />
            )}

            {platform === 'mobile' && (
              <OrdersListFooter
                page={data?.number}
                pageSize={params.size}
                totalPages={data?.total_pages}
                handlePageChange={({ selected }) =>
                  setParams({ page: selected })
                }
                handlePageSizeChange={({ value }) => setParams({ size: value })}
              />
            )}
          </Content>
        </OrderActionsProvider>
      }
      footer={
        platform !== 'mobile' && (
          <OrdersListFooter
            page={data?.number}
            pageSize={params.size}
            totalPages={data?.total_pages}
            handlePageChange={({ selected }) => setParams({ page: selected })}
            handlePageSizeChange={({ value }) => setParams({ size: value })}
          />
        )
      }
    />
  );
}
