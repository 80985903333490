import { DateConfigProvider } from '@superdispatch/dates';
import { FormsProvider } from '@superdispatch/forms';
import { dequal } from 'dequal';
import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from 'shared/data/AuthProvider';
import { defaultFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { AppErrorBoundary } from './shared/errors/AppErrorBoundary';
import { AppTheme } from './shared/theme/AppTheme';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      retryOnMount: false,
      isDataEqual: dequal,
      staleTime: 10 * 1000,
      cacheTime: 10 * 60 * 1000,
    },
  },
});

export function RootProvider({ children }: { children: ReactNode }) {
  return (
    <AppErrorBoundary>
      <AppTheme>
        <DateConfigProvider format="JodaISO">
          <FormsProvider getFormErrors={defaultFormErrorsGetter}>
            <QueryClientProvider client={queryClient}>
              {!('Cypress' in window) &&
                import.meta.env.MODE === 'development' && (
                  <ReactQueryDevtools initialIsOpen={false} />
                )}
              <AuthProvider>{children}</AuthProvider>
            </QueryClientProvider>
          </FormsProvider>
        </DateConfigProvider>
      </AppTheme>
    </AppErrorBoundary>
  );
}
