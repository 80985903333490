import { Tooltip, Typography } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { Inline, Stack, Tag } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { OrderRequestDTO } from 'orders/data/dto/OrderRequestDTO';
import { RequestDeclineReasons } from './OrderRequestDeclineReasons';

type RequestStatus = OrderRequestDTO['status'] | 'OFFER_CANCELED_BY_CARRIER';

export const requestStatusInfo: Record<
  Exclude<RequestStatus, 'REQUEST_PENDING'>,
  { text: string; isMuted?: boolean }
> = {
  REQUEST_DECLINED_BY_BROKER: { text: 'Declined', isMuted: true },
  REQUEST_CANCELED: { text: 'Carrier Canceled Request', isMuted: true },
  OFFER_SENT: { text: 'Offer Sent' },
  COUNTER_OFFER_SENT: { text: 'Counter Offer Sent' },
  OFFER_ACCEPTED: { text: 'Carrier Accepted Offer' },
  OFFER_CANCELED: { text: 'Offer Canceled', isMuted: true },
  OFFER_CANCELED_BY_CARRIER: { text: 'Carrier Canceled Order', isMuted: true },
  OFFER_DECLINED: { text: 'Carrier Declined Offer', isMuted: true },
  UNPOSTED: { text: 'Request Auto-Declined', isMuted: true },
};

function shouldShowPrice(status: RequestStatus) {
  return [
    'OFFER_SENT',
    'COUNTER_OFFER_SENT',
    'OFFER_ACCEPTED',
    'OFFER_DECLINED',
    'OFFER_CANCELED_BY_CARRIER',
  ].includes(status);
}

interface RequestStatusProps {
  status: RequestStatus;
  price: number;
  date: string;
}

export function RequestStatus({ status, price, date }: RequestStatusProps) {
  const statusInfo = requestStatusInfo[status];

  if (!statusInfo) return null;

  return (
    <Inline>
      {status !== 'UNPOSTED' ? (
        <Tag color={statusInfo.isMuted ? 'grey' : 'green'} variant="subtle">
          {statusInfo.text}
          {shouldShowPrice(status) && ` for $${price}`}
        </Tag>
      ) : (
        <Tooltip title="Request was auto-declined as the load offer was sent to another carrier or load was unposted.">
          <Tag color="grey" variant="subtle">
            {statusInfo.text}
          </Tag>
        </Tooltip>
      )}
      <Box>
        <Inline>
          <Typography color="textSecondary" variant="caption">
            <FormattedDate variant="Time" format="JodaISO" date={date} />,
          </Typography>
          <Typography color="textSecondary" variant="caption">
            <FormattedDate variant="ShortDate" format="JodaISO" date={date} />
          </Typography>
        </Inline>
      </Box>
    </Inline>
  );
}

export function OrderRequestStatusDetails({
  request,
}: {
  request: OrderRequestDTO;
}) {
  const {
    status,
    offer_cancel_reasons,
    offer_cancel_comment,
    changed_at,
    counter_offer_price,
    price,
  } = request;

  const isCarrierCanceledOffer =
    status === 'OFFER_CANCELED' &&
    (!!offer_cancel_reasons?.length || !!offer_cancel_comment);

  const requestStatus = isCarrierCanceledOffer
    ? 'OFFER_CANCELED_BY_CARRIER'
    : status;

  return (
    <Stack space="xsmall">
      {requestStatusInfo[requestStatus] && (
        <RequestStatus
          status={requestStatus}
          date={changed_at}
          price={counter_offer_price || price}
        />
      )}
      <RequestDeclineReasons status={requestStatus} request={request} />
    </Stack>
  );
}
