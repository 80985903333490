import { Link, Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Column,
  Columns,
  Stack,
  useSnackbarStack,
  useUID,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useCanExecute } from 'shared/data/UserPermissions';
import { useShipperProfileAPI } from '../shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../shipper-profile/data/ShipperProfileDTO';
import {
  MoreInformationExpandingBlock,
  SectionSwitch,
  SuperLoadboardSettingsSection,
} from './core/SuperLoadboardSettingsSection';
import { trackUpdatedSuperLoadboardSettingsValues } from './data/SuperLoadboardAnalytics';
import { useSuperLoadboardSettingsChannel } from './data/SuperLoadboardChannel';

interface LoadboardSectionProps {
  profile: ShipperProfileDTO;
}

export function SuperLoadboardInstantBooking({
  profile,
}: LoadboardSectionProps) {
  const id = useUID();
  const { addSnackbar } = useSnackbarStack();
  const canUpdateProfile = useCanExecute('UPDATE_COMPANY_PROFILE');
  const { updateLoadboardSettings } = useShipperProfileAPI();
  const { sendBroadcastMessage } = useSuperLoadboardSettingsChannel();

  const formik = useFormikEnhanced({
    initialValues: profile,
    onSubmit: (values) => {
      return updateLoadboardSettings(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      sendBroadcastMessage(undefined);
      trackUpdatedSuperLoadboardSettingsValues({
        name: 'Super Loadboard',
        is_instant_booking_allowed_for_verified_carriers:
          updatedValues.is_instant_booking_allowed_for_verified_carriers,
      });
      addSnackbar('Settings updated', { variant: 'success' });
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <SuperLoadboardSettingsSection data-intercom-target="instant booking settings">
      <Columns align="center">
        <Column width="fluid">
          <Stack space="xxsmall">
            <Typography variant="h4" id={id}>
              Instant Booking for Verified Carriers
            </Typography>
          </Stack>
        </Column>
        <Column width="content">
          <SectionSwitch
            aria-labelledby={id}
            disabled={!canUpdateProfile || formik.isSubmitting}
            checked={
              formik.values.is_instant_booking_allowed_for_verified_carriers
            }
            onChange={(_, checked) => {
              formik.setFieldValue(
                'is_instant_booking_allowed_for_verified_carriers',
                checked,
              );
              void formik.submitForm();
            }}
          />
        </Column>
      </Columns>

      <MoreInformationExpandingBlock>
        <Stack space="small">
          <Typography color="textSecondary">
            <Link
              color="inherit"
              target="_blank"
              rel="noreferrer"
              href="https://support.superdispatch.com/hc/articles/38186330348179-How-Does-Super-Dispatch-Monitor-Carrier-Compliance"
            >
              Verified Carriers
            </Link>{' '}
            meeting your{' '}
            <Link
              href="/profile/carrier-requirements/insurance/edit"
              rel="noreferrer"
              target="_blank"
              color="inherit"
            >
              cargo insurance minimum
            </Link>{' '}
            requirements and have not been blocklisted can instantly book your
            loads at the posted rate.
          </Typography>
          <Typography color="textSecondary">
            To book instantly, carriers must have active USDOT authority,
            maintain an overall rating of at least 80%, and keep their
            cancellation rate below 10%.
          </Typography>
          <Typography color="textSecondary">
            This feature will be restricted to all carriers with the following
            traits:
            <br />- Less than or equal to 90 days FMCSA registration
            <br />- Less than or equal to 3 ratings on SuperDispatch
            <br />- Less than or equal to 5 delivered loads on SuperDispatch
          </Typography>
          <Typography color="textSecondary">
            When enabled, Instant Booking will apply to all posted orders by
            default, unless disabled for sensitive loads when creating a new
            order.
          </Typography>
        </Stack>
      </MoreInformationExpandingBlock>

      <Box padding="xsmall" backgroundColor="Silver200" borderRadius="small">
        <TextBox color="secondary" variant="caption">
          By enabling this feature, you are confirming you have reviewed and
          agree to the Super Dispatch{' '}
          <Link
            color="inherit"
            target="_blank"
            rel="noreferrer"
            href="https://superdispatch.com/terms-of-service/"
          >
            Terms & Conditions
          </Link>
          .
        </TextBox>
      </Box>
    </SuperLoadboardSettingsSection>
  );
}
