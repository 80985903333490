import { Box, Link } from '@material-ui/core';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { round } from 'lodash-es';
import { useState } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { SourceManager } from 'shared/helpers/SourceManager';
import { RecommendedPriceIcon } from 'shared/icons/RecommendedPriceIcon';
import { Order } from 'shared/types/order';
import styled from 'styled-components';
import { OrderFormValues } from '../form/OrderForm';
import { PricingInsightsDrawer } from './PricingInsightsDrawer';
import {
  isOrderBelowRecommendedPrice,
  validateOrder,
} from './PricingRecommendationHelpers';

const BelowText = styled(TextBox)`
  color: ${ColorDynamic.Yellow500};
`;

const PointerLink = styled(Link)`
  cursor: pointer;
`;

const Container = styled.div`
  background-color: ${ColorDynamic.Silver200};
  display: inline-flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
`;

interface Props {
  order: Partial<Order> | OrderFormValues;
  onPriceClick: (value: number) => void;
  recommendedPrice?: number | null;
  source: string;
}

export function PricingRecommendation({
  order,
  onPriceClick,
  recommendedPrice = order.recommended_price,
  source,
}: Props) {
  const [isOpenPricingInsightsDrawer, setIsOpenPricingInsightsDrawer] =
    useState(false);

  if (validateOrder(order)) {
    return null;
  }

  const link = (
    <Link
      display="inline"
      href="#"
      onClick={(event) => {
        event.preventDefault();
        setIsOpenPricingInsightsDrawer(true);
        trackEvent('Shipper Clicked Pricing Insights Link');
      }}
    >
      Pricing Insights
    </Link>
  );

  if (!recommendedPrice) {
    return (
      <Inline space="xxsmall">
        <RecommendedPriceIcon />

        <TextBox color="secondary">
          Recommended Price not available for the chosen location.
        </TextBox>
      </Inline>
    );
  }

  return (
    <SourceManager secondarySource="Recommended Price">
      {!!order.price && order.price > recommendedPrice ? (
        <Container data-intercom-target="pricing recommendation">
          <Columns space="xxsmall" align="top">
            <Column width="content">
              <Box paddingTop="2px">
                <RecommendedPriceIcon fontSize="small" />
              </Box>
            </Column>

            <Column>
              <div>
                <TextBox>
                  {formatCurrency(recommendedPrice, {
                    maximumFractionDigits: 0,
                  })}{' '}
                  is recommended based on · ‎
                </TextBox>
                {link}
              </div>
            </Column>
          </Columns>
        </Container>
      ) : !!order.price &&
        round(order.price, 2) === round(recommendedPrice, 2) ? (
        <Container data-intercom-target="pricing recommendation">
          <Columns space="xxsmall" align="top">
            <Column width="content">
              <Box paddingTop="2px">
                <RecommendedPriceIcon fontSize="small" />
              </Box>
            </Column>

            <Column>
              <Stack space="none">
                <TextBox variant="body-semibold">Good Price</TextBox>
                <div>
                  <TextBox display="inline">
                    {formatCurrency(recommendedPrice, {
                      maximumFractionDigits: 0,
                    })}{' '}
                    is Recommended Price · ‎
                  </TextBox>
                  {link}
                </div>
              </Stack>
            </Column>
          </Columns>
        </Container>
      ) : isOrderBelowRecommendedPrice(order) ? (
        <Container data-intercom-target="pricing recommendation">
          <Columns space="xxsmall" align="top">
            <Column width="content">
              <Box paddingTop="2px">
                <RecommendedPriceIcon fontSize="small" />
              </Box>
            </Column>

            <Column>
              <Stack space="none">
                <BelowText variant="body-semibold">
                  Below Recommended Price
                </BelowText>

                <div>
                  <PointerLink
                    display="inline"
                    color="primary"
                    onClick={() => {
                      onPriceClick(recommendedPrice);
                      trackEvent('Shipper Clicked Set Recommended Price', {
                        utm_medium: source,
                      });
                    }}
                  >
                    Set to{' '}
                    {formatCurrency(recommendedPrice, {
                      maximumFractionDigits: 0,
                    })}
                  </PointerLink>

                  <TextBox display="inline">
                    &nbsp;for faster booking · ‎
                  </TextBox>
                  {link}
                </div>
              </Stack>
            </Column>
          </Columns>
        </Container>
      ) : (
        <Container data-intercom-target="pricing recommendation">
          <Columns space="xxsmall" align="top">
            <Column width="content">
              <Box paddingTop="2px">
                <RecommendedPriceIcon fontSize="small" />
              </Box>
            </Column>

            <Column>
              <Stack space="none">
                <TextBox variant="body-semibold">
                  {formatCurrency(recommendedPrice, {
                    maximumFractionDigits: 0,
                  })}{' '}
                  Recommended Price
                </TextBox>

                <Inline space="none">
                  <PointerLink
                    color="primary"
                    onClick={() => {
                      onPriceClick(recommendedPrice);
                      trackEvent('Shipper Clicked Set Recommended Price', {
                        utm_medium: source,
                      });
                    }}
                  >
                    Set to{' '}
                    {formatCurrency(recommendedPrice, {
                      maximumFractionDigits: 0,
                    })}
                  </PointerLink>

                  <TextBox>&nbsp;for faster booking · ‎</TextBox>
                  {link}
                </Inline>
              </Stack>
            </Column>
          </Columns>
        </Container>
      )}

      <PricingInsightsDrawer
        order={order as Partial<Order>}
        open={isOpenPricingInsightsDrawer}
        onClose={() => {
          setIsOpenPricingInsightsDrawer(false);
        }}
      />
    </SourceManager>
  );
}
