import { Menu, MenuItem, MenuItemProps } from '@material-ui/core';
import {
  AccountBalanceWallet,
  AccountCircle as ProfileIcon,
  Error,
  Settings as SettingsIcon,
  WarningRounded,
} from '@material-ui/icons';
import {
  Color,
  ColorDark,
  ColorDynamic,
  Inline,
  Stack,
  Tag,
} from '@superdispatch/ui';
import { Box, NavbarAvatar, useNavbarContext } from '@superdispatch/ui-lab';
import { isString } from 'lodash';
import { forwardRef, ReactNode, useState } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUserState } from '../data/AppUserState';
import { useFeatureToggle } from '../data/FeatureToggle';
import { LogoutIcon } from '../icons/LogoutIcon';
import { getInitials } from '../utils/TextUtils';

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledMenuIcon = styled(Box)`
  & svg {
    font-size: 24px;
    color: ${Color.Dark100};
  }
`;

const Wrapper = styled.div<{ active?: boolean }>`
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
  background: ${({ theme }) =>
    theme.palette.type === 'dark' ? ColorDark.White : Color.Dark500};
  border-left: 4px solid
    ${({ active }) => (active ? ColorDynamic.Blue300 : 'transparent')};
  border-top: 1px solid rgba(143, 148, 158, 0.3);
  &:hover {
    background: ${Color.Dark500};
    border-left-color: ${ColorDynamic.Blue500};
  }
`;

interface NavbarMenuItemProps extends Omit<MenuItemProps, 'button'> {
  icon: ReactNode;
  children: ReactNode;
  hasError?: boolean;
  hasWarning?: boolean;
  shouldShowNewTag?: boolean;
}

const NavbarMenuItem = forwardRef<HTMLLIElement, NavbarMenuItemProps>(
  (
    { icon, shouldShowNewTag, hasWarning, hasError, children, ...props },
    ref,
  ) => {
    return (
      <StyledMenuItem {...props} ref={ref}>
        <Inline space="small" verticalAlign="center" noWrap={true}>
          <StyledMenuIcon>{icon}</StyledMenuIcon>

          <Box color="Dark500">{children}</Box>
        </Inline>
        {hasError ? (
          <Error htmlColor={Color.Red300} fontSize="small" />
        ) : hasWarning ? (
          <WarningRounded fontSize="small" htmlColor={Color.Yellow300} />
        ) : shouldShowNewTag ? (
          <Tag color="blue" variant="subtle">
            New
          </Tag>
        ) : null}
      </StyledMenuItem>
    );
  },
);
NavbarMenuItem.displayName = 'NavbarMenuItem';

interface NavbarMenuLinkProps extends NavbarMenuItemProps {
  path: To;
}

const NavbarMenuLink = forwardRef<HTMLLIElement, NavbarMenuLinkProps>(
  ({ path, children, ...props }, ref) => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = (isString(path) ? path : path.pathname) || '';

    return (
      <NavbarMenuItem
        {...props}
        ref={ref}
        selected={location.pathname.startsWith(pathname)}
        onClick={() => {
          navigate(path);
        }}
      >
        {children}
      </NavbarMenuItem>
    );
  },
);
NavbarMenuLink.displayName = 'NavbarMenuLink';

export function AppNavbarMenu() {
  const navigate = useNavigate();
  const { user, isAdmin, isSuperUser, logout } = useUserState();
  const { setDrawerOpen } = useNavbarContext();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const isSubscriptionEnabled = useFeatureToggle(
    'update-credit-card-info.enabled',
  );
  const showSubscription =
    (isAdmin || isSuperUser) &&
    user?.shipper.is_self_serve &&
    isSubscriptionEnabled;

  const shipper = user?.shipper;
  const shipperType = shipper?.shipper_type;

  return (
    <Stack space="medium">
      <Wrapper
        active={!!anchor}
        onClick={(event) => {
          setAnchor(event.currentTarget);
        }}
      >
        <NavbarAvatar
          title={shipper?.name}
          subtitle={user?.username}
          src={shipper?.logo_url}
        >
          {getInitials(shipper?.name || '')}
        </NavbarAvatar>
      </Wrapper>

      <Menu
        open={!!anchor}
        anchorEl={anchor}
        autoFocus={false}
        onClose={() => {
          setAnchor(null);
        }}
        PaperProps={{
          style: { width: 240 },
          onClick: () => {
            setAnchor(null);
            setDrawerOpen(false);
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {shipperType === 'BROKER' && [
          <NavbarMenuLink key="1" path="/profile" icon={<ProfileIcon />}>
            Profile
          </NavbarMenuLink>,

          <NavbarMenuLink
            key="settings"
            path="/settings"
            icon={<SettingsIcon />}
          >
            Settings
          </NavbarMenuLink>,

          ...(showSubscription
            ? [
                <NavbarMenuLink
                  key="subscription"
                  path={{
                    pathname: '/subscription',
                    search: 'utm_medium=Subscription Details',
                  }}
                  icon={<AccountBalanceWallet />}
                >
                  Subscription Details
                </NavbarMenuLink>,
              ]
            : []),
        ]}

        <NavbarMenuItem
          icon={<LogoutIcon />}
          onClick={() => {
            logout();
            navigate('/signin');
          }}
        >
          Log Out
        </NavbarMenuItem>
      </Menu>
    </Stack>
  );
}
