import { LinearProgress, Typography } from '@material-ui/core';
import { Color, ColorDynamic, Column, Columns, Stack } from '@superdispatch/ui';
import { DescriptionLineItem } from '@superdispatch/ui-lab';
import styled from 'styled-components';

const StyledLinearProgress = styled(LinearProgress)`
  height: 12px;
  border-radius: 6px;
  background-color: ${ColorDynamic.Silver400};

  & .MuiLinearProgress-bar {
    background-color: ${({ isOverLimit }: { isOverLimit: boolean }) =>
      isOverLimit ? Color.Yellow300 : Color.Green300};
  }
`;

export function UsageIndicator({
  title,
  value,
  max,
  overageTitle,
}: {
  title: string;
  value: number;
  max: number;
  overageTitle: string;
}) {
  const isOverLimit = value > max;
  const progressValue = isOverLimit ? 100 : Math.round((value / max) * 100);
  const overage = value - max;

  return (
    <Stack space="xxsmall">
      <Stack>
        <Columns>
          <Column width="fluid">
            <Typography variant="h5">{title}</Typography>
          </Column>

          <Column width="content">
            <Typography variant="h5">
              <span style={{ color: isOverLimit ? Color.Red500 : undefined }}>
                {value}
              </span>{' '}
              / {max}
            </Typography>
          </Column>
        </Columns>

        <StyledLinearProgress
          value={progressValue}
          variant="determinate"
          isOverLimit={isOverLimit}
        />
      </Stack>

      {isOverLimit && (
        <DescriptionLineItem title={overageTitle}>
          <Typography variant="h4">{overage}</Typography>
        </DescriptionLineItem>
      )}
    </Stack>
  );
}
