import { Typography } from '@material-ui/core';
import { ColorDynamic, Image, Stack } from '@superdispatch/ui';
import { useEffect } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { Circle1Icon } from 'shared/icons/circle-1';
import { Circle2Icon } from 'shared/icons/circle-2';
import styled from 'styled-components';
import actionComplianceImgDark from '../assets/action-compliance-img-dark.svg';
import actionComplianceImg from '../assets/action-compliance-img.svg';
import actionPhoneVerificationImgDark from '../assets/action-phone-verification-img-dark.svg';
import actionPhoneVerificationImg from '../assets/action-phone-verification-img.svg';
import {
  OrderActionsAnalyticsProperties,
  trackVerificationApplicationEvent,
} from '../data/VerificationAnalyticsEvents';
import { VerificationOverlay } from './VerificationOverlay';
import { VerificationSidebar } from './VerificationSidebar';
import { VerificationStatusTag } from './VerificationStatusTag';

const VerificationActionRequiredBox = styled.div`
  max-width: 840px;

  display: flex;
  margin: 0 auto;
  padding: 32px 32px 64px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const VerificationActionRequiredSteps = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
    gap: 16px;
  }
`;

const TextBoxGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  max-width: 218px;
  max-height: 94px;
  flex: 1 0 0;
`;

const WhatsNextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const BlockWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 12px;

  & > img {
    margin-left: auto;
  }
`;

const Divider = styled.div`
  border-left: 1px solid ${ColorDynamic.Silver400};
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    border-left: none;
    border-bottom: 1px solid ${ColorDynamic.Silver400};
    width: 100%;
  }
`;

const EmailText = styled.div`
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
`;

function VerificationApplicationDialogContent() {
  const { user } = useUserState();
  const shipperEmail = user?.email;

  return (
    <VerificationActionRequiredBox>
      <WhatsNextBox>
        <Typography variant="h3">What happens now?</Typography>
        <VerificationActionRequiredSteps>
          <BlockWrapper>
            <Circle1Icon />
            <TextBoxGroup>
              <Typography variant="body1">
                Documentation Check Failed
              </Typography>
              <Typography>
                We were unable to verify one or more of your documents.
              </Typography>
            </TextBoxGroup>
            <Image
              src={actionComplianceImg}
              alt="compliance"
              width="65px"
              height="64px"
              srcDark={actionComplianceImgDark}
            />
          </BlockWrapper>

          <Divider />

          <BlockWrapper>
            <Circle2Icon />
            <TextBoxGroup>
              <Typography variant="body1">Check Your Email</Typography>
              <Typography>
                We sent instructions to: <EmailText>{shipperEmail}</EmailText>
              </Typography>
            </TextBoxGroup>
            <Image
              src={actionPhoneVerificationImg}
              alt="phone verification"
              width="65px"
              height="64px"
              srcDark={actionPhoneVerificationImgDark}
            />
          </BlockWrapper>
        </VerificationActionRequiredSteps>
      </WhatsNextBox>
    </VerificationActionRequiredBox>
  );
}

interface VerificationActionRequiredDialogProps
  extends OrderActionsAnalyticsProperties {
  open: boolean;
  onClose: () => void;
}

export function VerificationActionRequiredDialog({
  open,
  onClose,
  action,
  actionType,
}: VerificationActionRequiredDialogProps) {
  useEffect(() => {
    if (open) {
      trackVerificationApplicationEvent({
        name: 'Shipper Opened Verification Action Required Dialog',
      });
    }
  }, [open, action, actionType]);

  return (
    <VerificationOverlay
      open={open}
      onClose={onClose}
      sidebar={
        <VerificationSidebar status="error">
          <Stack space="small">
            <Typography variant="h2">Your verification status:</Typography>
            <VerificationStatusTag status="Action Required" />
          </Stack>
        </VerificationSidebar>
      }
    >
      <VerificationApplicationDialogContent />
    </VerificationOverlay>
  );
}
